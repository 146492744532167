import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    collection,
    doc,
    addDoc,
    deleteDoc,
    DocumentReference,
    query,
    where,
    getDocs
} from "firebase/firestore";
import { useAuth, useSignInWithPopup } from "../../../../hooks/hooks";
import Avatar from "../../../../common/Avatar/Avatar";
import AppButton from "../../../../common/AppButton/AppButton";
import FollowIcon from "../../../../assets/icons/FollowIcon";
import Loader from "../../../../common/Loader/Loader";
import { db } from "../../../../utils/firebase_config";
import { nFormatter } from "../../../../utils/functions";
import { IInfluencer } from "../../../../utils/types";
import style from "./InfluencerCard.module.scss";

interface IProps {
    influencer: IInfluencer;
    handleFollowAndUnfollowInfluencer: (influencerID: string) => void;
}

const InfluencerCard = ({ influencer, handleFollowAndUnfollowInfluencer }: IProps) => {
    const navigate = useNavigate();
    const { isAuthorized, user } = useAuth();
    const signInWithPopup = useSignInWithPopup();
    const userDoc = isAuthorized ? doc(db, "users", user.id) : null;
    const followingsRef = collection(db, "followings");
    const followedUserDoc = doc(db, "users", influencer.id);
    const [followingDoc, setFollowingDoc] = useState<DocumentReference | null>(null);
    const followingDocQuery = isAuthorized ? query(followingsRef,
        where("user", "==", userDoc),
        where("followedUser", "==", followedUserDoc)) : null;
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (influencer.isFollowedByCurrentUser && !followingDoc) {
            fetchFollowingDoc().then(data => setFollowingDoc(data));
        }
    }, [influencer.isFollowedByCurrentUser]);

    const fetchFollowingDoc = async () => {
        if (followingDocQuery) {
            const snapshot = await getDocs(followingDocQuery);
            return snapshot.docs[0].ref || null;
        } else {
            return null;
        }
    };

    const handleContainerClick = () => {
        navigate(influencer?.id === user.id ? "/my_profile" : `/user_profile/${influencer?.id}`);
    };

    const handleFollowAndUnfollowClick = async () => {
        setIsLoading(true);
        if (influencer.isFollowedByCurrentUser && followingDoc) {
            await deleteDoc(followingDoc);
            setFollowingDoc(null);
        } else {
            const newFollowingDoc = await addDoc(followingsRef, {
                user: userDoc,
                followedUser: followedUserDoc
            });
            setFollowingDoc(newFollowingDoc);
        }
        handleFollowAndUnfollowInfluencer(influencer.id);
        setIsLoading(false);
    };

    return (
        <div className={style.container} onClick={handleContainerClick}>
            {/*//TODO uncomment after the recommended By will be implemented*/}
            {/*{influencer.recommendedBy && <div className={style.recommended}>*/}
            {/*    <LikeIcon color="#2C56E4" isContained={false} />*/}
            {/*    <p>Recommended by <span>{influencer.recommendedBy}</span></p>*/}
            {/*</div>}*/}
            <div className={style.main}>
                <Avatar src={influencer.avatarUrl || ""} />
                <div className={style.influencerInfo}>
                    <h2>{influencer.displayName}</h2>
                    {/*//TODO uncomment after the recommended By will be implemented*/}
                    {/*{influencer.recommendedBy ? <h3>{influencer.email}</h3> :*/}
                    {/*    <h3>13K followers<span> - </span>13 products<span> - </span>4 lists</h3>}*/}
                    <h3>{nFormatter(influencer.followersCount, 0)} followers
                        <span> - </span>
                        {nFormatter(influencer.productsCount, 0)} products
                        <span> - </span>
                        {nFormatter(influencer.publicListsCount, 0)} lists</h3>
                </div>
                <AppButton type={influencer.isFollowedByCurrentUser ? "outlined" : "contained"}
                           disabled={isLoading} additionalClassNames={style.followButton}
                           children={
                               <>
                                   <FollowIcon color={influencer.isFollowedByCurrentUser ? "#2C56E4" : "#FFFFFF"}
                                               className={style.followIcon} />
                                   <p>{influencer.isFollowedByCurrentUser ? "Unfollow" : "Follow"}</p>
                                   {isLoading && <Loader className={style.loader} />}
                               </>
                           }
                           onClick={isAuthorized ? handleFollowAndUnfollowClick : signInWithPopup}
                />
            </div>
            {/*//TODO uncomment after the recommended By will be implemented*/}
            {/*{influencer.recommendedBy && <div className={style.extendedInfo}>*/}
            {/*    <div>*/}
            {/*        <h2>Followers</h2>*/}
            {/*        <h3>60.1K</h3>*/}
            {/*    </div>*/}
            {/*    <div>*/}
            {/*        <h2>Following</h2>*/}
            {/*        <h3>1,1613</h3>*/}
            {/*    </div>*/}
            {/*    <div>*/}
            {/*        <h2>Products</h2>*/}
            {/*        <h3>27</h3>*/}
            {/*    </div>*/}
            {/*    <div>*/}
            {/*        <h2>Lists</h2>*/}
            {/*        <h3>8</h3>*/}
            {/*    </div>*/}
            {/*</div>}*/}
        </div>
    );
};

export default InfluencerCard;