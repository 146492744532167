import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { DocumentReference } from "firebase/firestore";
import { useAuth } from "../../../hooks/hooks";
import NewProductPost from "../../../common/NewProductPost/NewProductPost";
import Toggle from "./Toggle/Toggle";
import Products from "./Products/Products";
import Lists from "./Lists/Lists";
import { IInfluencerWithBio, IList, IProductWithDescriptionAndUrl, toggleType } from "../../../utils/types";
import style from "./Main.module.scss";

interface IProps {
    isMyProfile: boolean;
    userRef: DocumentReference | null;
    userProfileData: IInfluencerWithBio | null;
    handleProductsCountChange: (isAdd: boolean) => void;
    handleListsCountChange: (isAdd: boolean) => void;
}

const Main = ({ isMyProfile, userRef, userProfileData, handleProductsCountChange, handleListsCountChange }: IProps) => {
    const location = useLocation();
    const { user } = useAuth();
    const isLists = location?.state?.isLists || null;
    const [toggleValue, setToggleValue] = useState<toggleType>("Products");
    const [userProducts, setUserProducts] = useState<IProductWithDescriptionAndUrl[]>([]);
    const [userLists, setUserLists] = useState<IList[]>([]);

    useEffect(() => {
        setToggleValue(isLists ? "Lists" : "Products");
    }, [isLists]);

    const handleProductAdd = (newProduct: IProductWithDescriptionAndUrl) => {
        setUserProducts(prevState => [...prevState, newProduct]);
        handleProductsCountChange(true);
    };

    const handleProductRemove = (id: string) => {
        setUserProducts(prevState => prevState.filter(el => el.id !== id));
        handleProductsCountChange(false);
    };

    const handleListAdd = (newList: IList) => {
        setUserLists(prevState => [...prevState, newList]);
        handleListsCountChange(true);
    };

    const handleListRemove = (id: string) => {
        setUserLists(prevState => prevState.filter(el => el.id !== id));
        handleListsCountChange(false);
    };

    return (
        <div className={style.container}>
            {isMyProfile &&
                <NewProductPost avatarUrl={user.avatarUrl || ""} additionalClassNames={style.newProductPost}
                                onProductAdd={handleProductAdd} />}
            <Toggle
                toggleValue={toggleValue}
                setToggleValue={setToggleValue}
                productsCount={userProfileData?.productsCount || 0}
                listsCount={userProfileData?.publicListsCount || 0} />
            {toggleValue === "Products" &&
                <Products
                    isMyProfile={isMyProfile}
                    userProfileData={userProfileData}
                    userRef={userRef}
                    userProducts={userProducts}
                    setUserProducts={setUserProducts}
                    handleProductRemove={handleProductRemove}
                    handleListAdd={handleListAdd} />}
            {toggleValue === "Lists" &&
                <Lists
                    isMyProfile={isMyProfile}
                    userProfileData={userProfileData}
                    userRef={userRef}
                    userLists={userLists}
                    setUserLists={setUserLists}
                    handleListRemove={handleListRemove} />}
        </div>
    );
};

export default Main;