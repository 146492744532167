import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { doc, updateDoc } from "firebase/firestore";
import Avatar from "../../../../common/Avatar/Avatar";
import { db } from "../../../../utils/firebase_config";
import { INotification } from "../../../../utils/types";
import style from "./NotificationItem.module.scss";

interface IProps {
    notification: INotification;
    userId: string;
    handleNotificationRead: (id: string) => void;
}

const NotificationItem = ({ notification, userId, handleNotificationRead }: IProps) => {
    const navigate = useNavigate();
    const notificationRef = doc(db, "users", userId, "notifications", notification.id);

    const handleClick = () => {
        navigate(`/user_profile/${notification.sender.id}`);
    };

    const readNotification = async () => {
        await updateDoc(notificationRef, {
            unread: false
        });
    };

    useEffect(() => {
        if (notification.unread) {
            readNotification().then(() => handleNotificationRead(notification.id));
        }
    }, [notification.unread]);

    return (
        <div className={style.notification}>
            <Avatar src={notification.sender.avatarUrl || ""} />
            <div className={style.info}>
                <h2 onClick={handleClick}>{notification.sender.displayName}</h2>
                <h3>{notification.type}</h3>
            </div>
        </div>
    );
};

export default NotificationItem;