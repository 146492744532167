import React from "react";
import moment from "moment";
import { useAuth } from "../../../hooks/hooks";
import NotificationItem from "./NotificationItem/NotificationItem";
import { INotification } from "../../../utils/types";
import style from "./NotificationList.module.scss";

interface IProps {
    notifications: INotification[];
    handleNotificationRead: (id: string) => void;
}

moment.updateLocale(moment.locale(), {
    week: {
        dow: 1
    }
});
const today = { start: moment().startOf("day"), end: moment().endOf("day") };
const yesterday = { start: moment().subtract(1, "day").startOf("day"), end: moment().subtract(1, "day").endOf("day") };
const thisWeek = { start: moment().startOf("week"), end: moment().endOf("week") };

const NotificationList = ({ notifications, handleNotificationRead }: IProps) => {
    const { user } = useAuth();
    const notificationsForToday = notifications.filter(notification => moment(notification.createdAt.toDate()).isBetween(today.start, today.end));
    const notificationsForYesterday = notifications.filter(notification => moment(notification.createdAt.toDate()).isBetween(yesterday.start, yesterday.end));
    const notificationsForThisWeek = notifications.filter(notification => moment(notification.createdAt.toDate()).isBetween(thisWeek.start, thisWeek.end) && !notificationsForToday.includes(notification) && !notificationsForYesterday.includes(notification));
    const restNotifications = notifications.filter(notification => moment(notification.createdAt.toDate()).isBefore(thisWeek.start));

    return (
        <div className={style.container}>
            {notificationsForToday.length > 0 && <p>Today</p>}
            {notificationsForToday.map(notification =>
                <NotificationItem key={notification.id}
                                  notification={notification}
                                  userId={user.id}
                                  handleNotificationRead={handleNotificationRead} />)}
            {notificationsForYesterday.length > 0 && <p>Yesterday</p>}
            {notificationsForYesterday.map(notification =>
                <NotificationItem key={notification.id}
                                  notification={notification}
                                  userId={user.id}
                                  handleNotificationRead={handleNotificationRead} />)}
            {notificationsForThisWeek.length > 0 && <p>This week</p>}
            {notificationsForThisWeek.map(notification =>
                <NotificationItem key={notification.id}
                                  notification={notification}
                                  userId={user.id}
                                  handleNotificationRead={handleNotificationRead} />)}
            {restNotifications.length > 0 && <p>Old</p>}
            {restNotifications.map(notification =>
                <NotificationItem key={notification.id}
                                  notification={notification}
                                  userId={user.id}
                                  handleNotificationRead={handleNotificationRead} />)}
        </div>
    );
};

export default NotificationList;