import React from "react";

const LinkIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.0355 14.0355C10.3614 14.3681 10.7504 14.6324 11.1797 14.8127C11.6089 14.9931 12.0699 15.086 12.5355 15.086C13.0012 15.086 13.4621 14.9931 13.8914 14.8127C14.3207 14.6324 14.7097 14.3681 15.0355 14.0355L19.0355 10.0355C19.6986 9.37249 20.0711 8.47322 20.0711 7.53553C20.0711 6.59785 19.6986 5.69858 19.0355 5.03553C18.3725 4.37249 17.4732 4 16.5355 4C15.5979 4 14.6986 4.37249 14.0355 5.03553L13.5355 5.53553M14.0355 10.0355C13.7097 9.70294 13.3207 9.43871 12.8914 9.25832C12.4621 9.07794 12.0012 8.98502 11.5355 8.98502C11.0699 8.98502 10.6089 9.07794 10.1797 9.25832C9.75038 9.43871 9.36141 9.70294 9.03553 10.0355L5.03553 14.0355C4.37249 14.6986 4 15.5979 4 16.5355C4 17.4732 4.37249 18.3725 5.03553 19.0355C5.69858 19.6986 6.59785 20.0711 7.53553 20.0711C8.47322 20.0711 9.37249 19.6986 10.0355 19.0355L10.5355 18.5355"
                stroke="#292929" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default LinkIcon;