import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
    collection,
    doc, DocumentReference,
    getDoc,
    getDocs,
    query,
    QuerySnapshot,
    where
} from "firebase/firestore";
import { useAuth } from "../../hooks/hooks";
import Leftbar from "./Leftbar/Leftbar";
import Main from "./Main/Main";
import { db } from "../../utils/firebase_config";
import { IInfluencerWithBio } from "../../utils/types";
import style from "./UserProfilePage.module.scss";

interface IProps {
    isMyProfile: boolean;
}

const UserProfilePage = ({ isMyProfile }: IProps) => {
    const { user, isAuthorized } = useAuth();
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const isLists = location?.state?.isLists || false;
    const id = isMyProfile ? user.id : params.id;
    const currentUserRef = isAuthorized ? doc(db, "users", user.id) : null;
    const userRef = id ? doc(db, "users", id) : null;
    const followingsRef = collection(db, "followings");
    const isFollowedQuery = query(followingsRef,
        where("user", "==", currentUserRef),
        where("followedUser", "==", userRef));
    const [followingDoc, setFollowingDoc] = useState<DocumentReference | null>(null);
    const [userProfileData, setUserProfileData] = useState<IInfluencerWithBio | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        fetchUserData();
    }, [id, isAuthorized]);

    useEffect(() => {
        if (isAuthorized && user.id === id) {
            navigate("/my_profile", { state: { isLists }, replace: true });
        }
    }, [isAuthorized]);

    const fetchUserData = async () => {
        if (id && userRef && isFollowedQuery) {
            setIsLoading(true);
            const docSnap = await getDoc(userRef);
            const {
                displayName,
                email,
                avatarUrl,
                followersCount,
                followingsCount,
                productsCount,
                privateListsCount,
                bio
            } = docSnap.data() as Omit<IInfluencerWithBio, "id" | "isFollowedByCurrentUser"> & { privateListsCount: number };

            let { publicListsCount } = docSnap.data() as Omit<IInfluencerWithBio, "id" | "isFollowedByCurrentUser"> & { privateListsCount: number };
            publicListsCount = isMyProfile ? publicListsCount + privateListsCount : publicListsCount;

            setUserProfileData({
                id,
                displayName,
                email,
                avatarUrl,
                followersCount,
                followingsCount,
                productsCount,
                publicListsCount,
                bio,
                isFollowedByCurrentUser: false
            });

            if (isAuthorized) {
                const isFollowedPromise: Promise<QuerySnapshot> = getDocs(isFollowedQuery);
                await Promise.resolve(isFollowedPromise).then(snapshot => {
                    if (snapshot.size === 1) setUserProfileData(prevState => prevState ? ({
                        ...prevState,
                        isFollowedByCurrentUser: true
                    }) : prevState);
                    snapshot.forEach(doc => setFollowingDoc(doc.ref));
                });
            }

            setIsLoading(false);
        }
    };

    const handleFollowAndUnfollowUser = () => {
        setUserProfileData(prevState => {
            return prevState ?
                {
                    ...prevState,
                    followersCount: prevState.isFollowedByCurrentUser ? prevState.followersCount - 1 : prevState.followersCount + 1,
                    isFollowedByCurrentUser: !prevState.isFollowedByCurrentUser
                } : prevState;
        });
    };

    const handleProductsCountChange = (isAdd: boolean) => {
        setUserProfileData(prevState => prevState ? ({
            ...prevState,
            productsCount: prevState.productsCount + (isAdd ? 1 : -1)
        }) : prevState);
    };

    const handleListsCountChange = (isAdd: boolean) => {
        setUserProfileData(prevState => prevState ? ({
            ...prevState,
            publicListsCount: prevState.publicListsCount + (isAdd ? 1 : -1)
        }) : prevState);
    };

    return (
        <div className={style.container}>
            <Leftbar isMyProfile={isMyProfile}
                     userProfileData={userProfileData}
                     followingDoc={followingDoc}
                     followingsRef={followingsRef}
                     currentUserRef={currentUserRef}
                     userRef={userRef}
                     handleFollowAndUnfollowUser={handleFollowAndUnfollowUser}
                     setFollowingDoc={setFollowingDoc}
                     isLoading={isLoading}
            />
            <Main
                isMyProfile={isMyProfile}
                userRef={userRef}
                userProfileData={userProfileData}
                handleProductsCountChange={handleProductsCountChange}
                handleListsCountChange={handleListsCountChange} />
        </div>
    );
};

export default UserProfilePage;