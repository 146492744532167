import classnames from "classnames";
import {
    addDoc,
    arrayUnion,
    collection,
    doc,
    getDoc,
    Timestamp,
    updateDoc
} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { v4 } from "uuid";
import * as Yup from "yup";
import DropdownCaretIcon from "../../assets/icons/DropdownCaretIcon";
import TrashIcon from "../../assets/icons/TrashIcon";
import { useAuth } from "../../hooks/hooks";
import { db, storage } from "../../utils/firebase_config";
import { IFetchInfluencerData, IListReduced, IProductWithDescriptionAndUrl } from "../../utils/types";
import AppButton from "../AppButton/AppButton";
import Loader from "../Loader/Loader";
import SelectLists from "../SelectLists/SelectLists";
import style from "./AddNewOrEditProductPopup.module.scss";

interface IFormData {
    title: string;
    description: string;
    url: string;
    lists: IListReduced[];
    file: File | null;
}

const emptyFormData: IFormData = {
    title: "",
    description: "",
    url: "",
    lists: [],
    file: null
};

interface IProps {
    setIsModalOpen: (value: boolean) => void;
    onProductAdd?: (newProduct: IProductWithDescriptionAndUrl) => void;
    isEdit: boolean;
    productFormData?: IFormData & { id: string };
    imageOfExistingProduct?: string;
    onProductEdit?: (id: string, newTitle: string, newDescription: string, newUrl: string, newImageUrl: string) => void;
}

const AddNewOrEditProductPopup = ({
                                      setIsModalOpen,
                                      onProductAdd,
                                      isEdit,
                                      productFormData = { ...emptyFormData, id: "" },
                                      imageOfExistingProduct,
                                      onProductEdit
                                  }: IProps) => {
    const { user } = useAuth();
    const userRef = doc(db, "users", user.id);
    const productsRef = collection(db, "products");
    const inputRef = useRef<HTMLInputElement>(null);
    const [isAddNewOrEditListPopup, setIsAddNewOrEditListPopup] = useState<boolean>(false);
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    // eslint-disable-next-line no-useless-escape
    const regMatch = /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;

    const formik = useFormik({
        initialValues: (isEdit && productFormData) ? { ...productFormData } : { ...emptyFormData },
        validationSchema: Yup.object({
            title: Yup.string().required("Required field"),
            description: Yup.string().required("Required field"),
            url: Yup.string().matches(
                regMatch,
                "Enter correct url!"
            ),
            file: isEdit ? Yup.mixed() : Yup.mixed().required()
        }),
        onSubmit: async (values: IFormData) => {
            setIsLoading(true);

            let imageRelativePath: string | null = null;
            let imageUrl: string | null = null;

            if (values.file) {
                imageRelativePath = `${v4()}.${values.file.type.split("/")[1]}`;
                const imageRef = ref(storage, `products/${imageRelativePath}`);
                await uploadBytes(imageRef, values.file);
                imageUrl = await getDownloadURL(imageRef);
            }

            const { title, description, url } = values;

            const userDoc = await getDoc(userRef);

            const {
                followersCount,
                followingsCount,
                productsCount,
                publicListsCount
            } = userDoc.data() as IFetchInfluencerData;

            const listsPromises: Promise<void>[] = [];

            if (isEdit && imageOfExistingProduct) {
                const productRef = doc(db, "products", productFormData?.id);

                if (imageUrl && imageRelativePath) {
                    await updateDoc(productRef, {
                        title,
                        _title: title.toLowerCase(),
                        description,
                        url,
                        imageUrl,
                        imageRelativePath
                    });
                } else {
                    await updateDoc(productRef, {
                        title,
                        _title: title.toLowerCase(),
                        description,
                        url
                    });
                }

                values.lists.forEach(list => {
                    const listRef = doc(db, "lists", list.id);
                    listsPromises.push(updateDoc(listRef, {
                        productsReferences: arrayUnion(productRef)
                    }));
                });

                Promise.all(listsPromises).then(() => {
                    setIsLoading(false);
                    setIsModalOpen(false);
                });

                onProductEdit && onProductEdit(productFormData?.id, title, description, url, imageUrl || imageOfExistingProduct);
            } else if (imageUrl) {
                const newProduct: Omit<IProductWithDescriptionAndUrl, "id"> = {
                    influencer: {
                        ...user,
                        followersCount,
                        followingsCount,
                        productsCount,
                        publicListsCount,
                        isFollowedByCurrentUser: false
                    },
                    title,
                    description,
                    url,
                    imageUrl,
                    createdAt: Timestamp.now(),
                    averageRating: 0,
                    reviewsCount: 0
                };

                const createdProduct = await addDoc(productsRef, {
                    ...newProduct,
                    influencer: userRef,
                    imageRelativePath,
                    likes: [],
                    dislikes: [],
                    starCounts: {
                        1: 0,
                        2: 0,
                        3: 0,
                        4: 0,
                        5: 0
                    },
                    _title: title.toLowerCase()
                });

                values.lists.forEach(list => {
                    const listRef = doc(db, "lists", list.id);
                    listsPromises.push(updateDoc(listRef, {
                        productsReferences: arrayUnion(createdProduct)
                    }));
                });

                Promise.all(listsPromises).then(() => {
                    setIsLoading(false);
                    setIsModalOpen(false);
                });

                onProductAdd && onProductAdd({ ...newProduct, id: createdProduct.id });
            }
        }
    });

    useEffect(() => {
        if (formik.values.file) {
            setImageUrl(URL.createObjectURL(formik.values.file));
        } else {
            setImageUrl("");
        }
    }, [formik.values.file]);

    const handleContainerClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        setIsAddNewOrEditListPopup(false);
    };

    const handleSelectListsWrapperClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        setIsAddNewOrEditListPopup(true);
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            formik.setFieldValue("file", e.target.files[0]);
        }
    };

    const handleListsChange = (lists: IListReduced[]) => {
        formik.setFieldValue("lists", lists);
    };

    const handleFileRemove = () => {
        formik.setFieldValue("file", null);
        if (inputRef.current) {
            inputRef.current.value = "";
        }
    };

    return (
        <div className={style.container} onClick={handleContainerClick}>
            <input id="title" type="text" placeholder="Title"
                   className={classnames(formik.touched.title && formik.errors.title && style.notAllowedFieldValue)}
                   value={formik.values.title} onChange={formik.handleChange}
                   onBlur={formik.handleBlur}
            />
            <textarea id="description"
                      placeholder="Description"
                      className={classnames(formik.touched.description && formik.errors.description && style.notAllowedFieldValue)}
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur} />
            <input id="url"
                   type="url"
                   placeholder="URL (optional)"
                   className={classnames(style.url, formik.touched.url && formik.errors.url && style.notAllowedFieldValue)}
                   value={formik.values.url}
                   onChange={formik.handleChange}
                   onBlur={formik.handleBlur}
            />
            <div className={style.selectListsWrapper} onClick={handleSelectListsWrapperClick}>
                <p className={style.text}>{formik.values.lists.length > 0 ? formik.values.lists.map(el => el.name).join(", ") : "Add to List"}</p>
                <DropdownCaretIcon color="#A3A3A3" />
                {isAddNewOrEditListPopup &&
                    <SelectLists setIsAddToListsPopupOpen={setIsAddNewOrEditListPopup}
                                 additionalClassnames={style.selectList}
                                 isFromAddOrEditProductPopup={true}
                                 onConfirmIfIsFromAddOrEditProductPopup={handleListsChange}
                                 initialSelectedLists={formik.values.lists} />
                }
            </div>
            <label htmlFor="file"
                   className={classnames(style.dragAndDrop, imageUrl && style.dragAndDropDisabled, formik.touched.file && formik.errors.file && style.notAllowedFileInputValue)}>
                {imageUrl ?
                    <>
                        <img src={imageUrl} alt="Selected file" />
                        <AppButton type="text" disabled={false} additionalClassNames={style.removeImageButton}
                                   children={<TrashIcon color="#E00000" />}
                                   onClick={handleFileRemove}
                        />
                    </> :
                    <p>+ Add {isEdit && "New"} Image</p>}
            </label>
            <input ref={inputRef} id="file" type="file" accept="image/*"
                   className={style.fileInput}
                   onChange={handleFileChange}
                   onBlur={formik.handleBlur}
                   disabled={Boolean(imageUrl)}
            />
            <AppButton type="contained" disabled={isLoading} additionalClassNames={style.btn}
                       children={<>{isLoading ? <Loader className={style.loader} /> :
                           <p>{isEdit ? "+ Edit Product" : "+ Add Product"}</p>}</>}
                       onClick={formik.handleSubmit}
            />
        </div>
    );
};

export default AddNewOrEditProductPopup;