import React from "react";
import classnames from "classnames";
import style from "./AppButton.module.scss";

interface IProps {
    children: React.ReactNode,
    type: "contained" | "outlined" | "text",
    disabled: boolean,
    additionalClassNames: string,
    onClick?: () => void;
}

const AppButton = ({ children, type, disabled, additionalClassNames, onClick }: IProps) => {
    return (
        <button type="button"
                className={classnames(style.main, style[type], disabled && style["disabled"], additionalClassNames)}
                disabled={disabled}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onClick && onClick();
                }}>
            {children}
        </button>
    );
};

export default AppButton;