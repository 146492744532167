import React from "react";

const EditIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.5 5.82843L17.5 9.82843M4 19.3284H8L18.5 8.82843C19.0304 8.29799 19.3284 7.57857 19.3284 6.82843C19.3284 6.07828 19.0304 5.35886 18.5 4.82843C17.9696 4.29799 17.2501 4 16.5 4C15.7499 4 15.0304 4.29799 14.5 4.82843L4 15.3284V19.3284Z"
                stroke="#292929" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default EditIcon;