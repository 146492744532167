import React, { useState } from "react";
import classnames from "classnames";
import Avatar from "../Avatar/Avatar";
import AppButton from "../AppButton/AppButton";
import AddNewOrEditProductPopup from "../AddNewOrEditProductPopup/AddNewOrEditProductPopup";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import { IProductWithDescriptionAndUrl } from "../../utils/types";
import style from "./NewProductPost.module.scss";

interface IProps {
    avatarUrl: string;
    additionalClassNames?: string;
    onProductAdd?: (newProduct: IProductWithDescriptionAndUrl) => void;
}

const NewProductPost = ({ avatarUrl, additionalClassNames, onProductAdd }: IProps) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    return (
        <div className={classnames(style.container, additionalClassNames)}>
            <Avatar src={avatarUrl} />
            <h2>Got a new product?</h2>
            <AppButton type="contained" disabled={false} additionalClassNames={style.button}
                       children={<p>Post it!</p>}
                       onClick={() => setIsModalOpen(true)}
            />
            {isModalOpen &&
                <ModalWrapper onClick={() => setIsModalOpen(false)}>
                    <AddNewOrEditProductPopup setIsModalOpen={setIsModalOpen} onProductAdd={onProductAdd} isEdit={false}/>
                </ModalWrapper>
            }
        </div>
    );
};

export default NewProductPost;