import React from "react";

interface IProps {
    className?: string;
}

const CloseIcon = ({ className }: IProps) => {
    return (
        <svg className={className} width="24" height="24" viewBox="0 0 24 24"
             strokeWidth="2" stroke="#081029" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <line x1="24" y1="0" x2="0" y2="24"></line>
            <line x1="0" y1="0" x2="24" y2="24"></line>
        </svg>
    );
};

export default CloseIcon;