import React from "react";
import CheckboxCheckedIcon from "../../../assets/icons/CheckboxCheckedIcon";
import CheckboxUncheckedIcon from "../../../assets/icons/CheckboxUncheckedIcon";
import LocksmithIcon from "../../../assets/icons/LocksmithIcon";
import { IListReduced } from "../../../utils/types";
import style from "./ListItem.module.scss";

interface IProps {
    value: IListReduced;
    isChecked: (item: IListReduced) => boolean;
    handleListsChange: (isAdd: boolean, currentList: IListReduced) => void;
}

const ListItem = ({ value, handleListsChange, isChecked }: IProps) => {
    const checked = isChecked(value);

    const handleClick = () => {
        handleListsChange(!checked, value);
    };

    return (
        <li onClick={handleClick} className={style.container}>
            {checked ?
                <CheckboxCheckedIcon className={style.icon} /> :
                <CheckboxUncheckedIcon className={style.icon} />}
            <p className={style.text}>{value.name}</p>
            {value.accessStatus === "private" && <LocksmithIcon />}
        </li>
    );
};

export default ListItem;