import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
    doc,
    getDoc,
    Timestamp,
    DocumentReference,
    QuerySnapshot,
    collection,
    getDocs,
    query,
    orderBy,
    limit,
    startAfter,
    where,
    addDoc
} from "firebase/firestore";
import classnames from "classnames";
import { useAuth } from "../../hooks/hooks";
import Header from "./Header/Header";
import Influencer from "./Influencer/Influencer";
import Description from "./Description/Description";
import Ratings from "./Ratings/Ratings";
import Review from "./Review/Review";
import CurrentProductList from "./CurrentProductList/CurrentProductList";
import AppButton from "../../common/AppButton/AppButton";
import DropdownCaretIcon from "../../assets/icons/DropdownCaretIcon";
import Loader from "../../common/Loader/Loader";
import ModalWrapper from "../../common/ModalWrapper/ModalWrapper";
import AddNewOrEditProductPopup from "../../common/AddNewOrEditProductPopup/AddNewOrEditProductPopup";
import { db } from "../../utils/firebase_config";
import { IFetchInfluencerData, IProductDetails, IReview, starCountsType } from "../../utils/types";
import style from "./ProductPage.module.scss";

interface IFetchDetailsDocumentData {
    title: string;
    imageUrl: string;
    createdAt: Timestamp;
    influencer: DocumentReference;
    description: string;
    url: string;
    likes: string[];
    dislikes: string[];
    reviewsCount: number;
    averageRating: number;
    starCounts: starCountsType;
}


const ProductPage = () => {
    const location = useLocation();
    const isFromList: boolean = location.state.from === "list";
    const [isUserProduct, setIsUserProduct] = useState<boolean>(false);
    const params = useParams();
    const { id } = params;
    const listId = location.state.listId as string;
    const { user, isAuthorized } = useAuth();
    const userRef = isAuthorized ? doc(db, "users", user.id) : null;
    const productRef = doc(db, `products/${id}`);
    const reviewsRef = collection(db, `products/${id}`, "reviews");
    const followingsRef = collection(db, "followings");
    const [lastReviewKey, setLastReviewKey] = useState<Timestamp | null>(null);
    const [productDetails, setProductDetails] = useState<IProductDetails | null>(null);
    const [isProductDetailsLoading, setIsProductDetailsLoading] = useState<boolean>(false);
    const [isProductReviewsLoading, setIsProductReviewsLoading] = useState<boolean>(false);
    const [isEditProductPopupOpen, setIsEditProductPopupOpen] = useState<boolean>(false);

    useEffect(() => {
        fetchDetails().then(data => {
            setProductDetails(data);
            setIsProductReviewsLoading(true);
            fetchReviews(false).then(data => {
                setIsProductReviewsLoading(false);
                setProductDetails(prevState => prevState ? ({
                    ...prevState,
                    reviews: [...data.reviews],
                    reviewsCount: data.updatedReviewsCount
                }) : prevState);
            });
        });
    }, [id, isAuthorized]);

    useEffect(() => {
        if (productDetails) {
            setIsUserProduct(productDetails.influencer?.id === user.id);
        }
    }, [productDetails]);

    const fetchDetails = async () => {
        setIsProductDetailsLoading(true);

        const docSnap = await getDoc(productRef);
        const { id } = docSnap;
        const {
            title,
            imageUrl,
            createdAt,
            influencer: influencerReference,
            description,
            url,
            likes,
            dislikes,
            reviewsCount,
            averageRating,
            starCounts
        } = docSnap.data() as IFetchDetailsDocumentData;

        const influencerSnap = await getDoc(influencerReference);
        const { id: influencerID } = influencerSnap;
        let influencer = {
            id: influencerID,
            isFollowedByCurrentUser: false,
            ...influencerSnap.data() as IFetchInfluencerData
        };

        let isReviewedByCurrentUser: boolean = false;

        if (isAuthorized) {
            const isFollowedQuery = query(followingsRef,
                where("user", "==", userRef),
                where("followedUser", "==", influencerSnap.ref));

            const isFollowedPromise: Promise<QuerySnapshot> = getDocs(isFollowedQuery);

            await Promise.resolve(isFollowedPromise).then(snapshot => {
                if (snapshot.size === 1) influencer.isFollowedByCurrentUser = true;
            });

            const isReviewedQuery = query(reviewsRef, where("author", "==", userRef));

            const isReviewedPromise: Promise<QuerySnapshot> = getDocs(isReviewedQuery);

            await Promise.resolve(isReviewedPromise).then(snapshot => {
                if (snapshot.size === 1) isReviewedByCurrentUser = true;
            });
        }

        setIsProductDetailsLoading(false);

        return {
            id,
            title,
            imageUrl,
            createdAt,
            influencer,
            description,
            url,
            likes,
            dislikes,
            reviewsCount,
            averageRating,
            starCounts,
            reviews: [],
            isReviewedByCurrentUser
        };
    };

    const fetchReviews = async (isMore: boolean) => {
        const docSnap = await getDoc(productRef);
        const { reviewsCount: updatedReviewsCount } = docSnap.data() as IFetchDetailsDocumentData;

        let reviews: IReview[] = [];
        const reviewsQuery = isMore ? query(reviewsRef, orderBy("createdAt"), startAfter(lastReviewKey), limit(10)) : query(reviewsRef, orderBy("createdAt"), limit(3));
        const reviewsSnapshot = await getDocs(reviewsQuery);

        const reviewsAuthorPromises: Promise<any>[] = [];

        reviewsSnapshot.forEach(doc => {
            const { id } = doc;
            const { createdAt, text, rating, author, likes } = doc.data();
            reviews.push({ id, createdAt, text, rating, author, likes });
            const { author: authorReference } = doc.data();
            reviewsAuthorPromises.push(getDoc(authorReference));
        });

        await Promise
            .all(reviewsAuthorPromises)
            .then(values => {
                reviews = reviews.map((review, index) => {
                    return { ...review, author: { id: values[index].id, ...values[index].data() } };
                });
            });

        if (reviews.length > 0) {
            setLastReviewKey(reviews[reviews.length - 1].createdAt);
        }

        return { reviews, updatedReviewsCount };
    };

    const handleProductLikes = (isAddLike: boolean) => {
        setProductDetails(prevState => prevState ? ({
            ...prevState,
            likes: isAddLike ? [...prevState.likes, user.id] : prevState.likes.filter(el => el !== user.id)
        }) : prevState);
    };

    const handleProductDislikes = (isAddDislike: boolean) => {
        setProductDetails(prevState => prevState ? ({
            ...prevState,
            dislikes: isAddDislike ? [...prevState.dislikes, user.id] : prevState.dislikes.filter(el => el !== user.id)
        }) : prevState);
    };

    const handleProductReviewLikes = (isAddLike: boolean, reviewId: string) => {
        setProductDetails(prevState => prevState ? ({
            ...prevState,
            reviews: prevState.reviews.map(el => el.id === reviewId ? {
                ...el,
                likes: isAddLike ? [...el.likes, user.id] : el.likes.filter(like => like !== user.id)
            } : el)
        }) : prevState);
    };

    const handleFollowAndUnfollowInfluencer = () => {
        setProductDetails(prevState => {
            if (prevState?.influencer) {
                return {
                    ...prevState,
                    influencer: {
                        ...prevState.influencer,
                        followersCount: prevState.influencer.isFollowedByCurrentUser ? prevState.influencer.followersCount - 1 : prevState.influencer.followersCount + 1,
                        isFollowedByCurrentUser: !prevState.influencer.isFollowedByCurrentUser
                    }
                };
            } else {
                return prevState;
            }
        });
    };

    const handleAddReview = async (rating: number, text: string) => {
        const newReview: Omit<IReview, "id" | "author"> = {
            createdAt: Timestamp.now(),
            likes: [],
            rating,
            text
        };
        const { id } = await addDoc(reviewsRef, {
            ...newReview,
            author: userRef
        });
        setProductDetails(prevState => prevState ? {
            ...prevState,
            reviewsCount: prevState.reviewsCount + 1,
            averageRating: (prevState.averageRating * prevState.reviews.length + rating) / (prevState.reviews.length + 1),
            starCounts: {
                ...prevState.starCounts,
                [rating]: prevState.starCounts[rating as 1 | 2 | 3 | 4 | 5] + 1
            },
            reviews: [...prevState.reviews, { ...newReview, id, author: user }],
            isReviewedByCurrentUser: true
        } : prevState);
    };

    const handleLoadMoreReviewsButtonClick = () => {
        setIsProductReviewsLoading(true);

        fetchReviews(true).then(data => setProductDetails(prevState => {
            const newUniqueData = data.reviews.filter(el => !prevState?.reviews.find(el1 => el1.id === el.id));

            setIsProductReviewsLoading(false);

            return prevState ? ({
                ...prevState,
                reviews: [...prevState.reviews, ...newUniqueData].sort((a, b) => b.createdAt.nanoseconds - a.createdAt.nanoseconds),
                reviewsCount: data.updatedReviewsCount
            }) : prevState;
        }));
    };

    const handleUpdateProductDetailsAfterEdit = (id: string, title: string, description: string, url: string, imageUrl: string) => {
        setProductDetails(prevState => prevState ? { ...prevState, title, description, url, imageUrl } : prevState);
    };

    const handleEditReview = (id: string, newText: string, newRating: number, oldRating: number) => {
        setProductDetails(prevState => {
            return prevState ? ({
                ...prevState,
                reviews: prevState.reviews.map(el => el.id === id ? { ...el, text: newText, rating: newRating } : el),
                averageRating: (prevState.averageRating * prevState.reviews.length - oldRating + newRating) / prevState.reviews.length,
                starCounts: {
                    ...prevState.starCounts,
                    [oldRating]: prevState.starCounts[oldRating as 1 | 2 | 3 | 4 | 5] - 1,
                    [newRating]: prevState.starCounts[newRating as 1 | 2 | 3 | 4 | 5] + 1
                }
            }) : prevState;
        });
    };

    return (
        <>
            <section className={style.container}>
                {isFromList && <CurrentProductList listId={listId} productId={id || null} />}
                <div className={classnames(style.content, isProductDetailsLoading && style.transparentContent)}>
                    {isProductDetailsLoading ? <Loader className={style.loader} /> :
                        <>
                            <img
                                src={productDetails?.imageUrl || ""}
                                alt="Product" className={style.productImg} />
                            <Header
                                id={productDetails?.id || ""}
                                title={productDetails?.title || ""}
                                likes={productDetails?.likes || []}
                                dislikes={productDetails?.dislikes || []}
                                handleProductLikes={handleProductLikes}
                                handleProductDislikes={handleProductDislikes}
                                productRef={productRef} isUserProduct={isUserProduct}
                                setIsEditProductPopupOpen={setIsEditProductPopupOpen} />
                            <Influencer influencer={productDetails?.influencer}
                                        isUserProduct={isUserProduct}
                                        handleFollowAndUnfollowInfluencer={handleFollowAndUnfollowInfluencer} />
                            <Description text={productDetails?.description || ""} url={productDetails?.url || ""} id={productDetails?.id || ""} />
                            <Ratings isUserProduct={isUserProduct}
                                     reviewsCount={productDetails?.reviewsCount || 0}
                                     averageRating={productDetails?.averageRating || 0}
                                     starCounts={productDetails?.starCounts || { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 }}
                                     handleAddReview={handleAddReview}
                                     isReviewedByCurrentUser={Boolean(productDetails?.isReviewedByCurrentUser)} />
                            <div>
                                {productDetails?.reviews.map((review, index) =>
                                    <Review key={index} review={review} productId={productDetails?.id}
                                            handleProductReviewLikes={handleProductReviewLikes}
                                            onEdit={handleEditReview} />
                                )}
                                {isProductReviewsLoading ?
                                    <Loader
                                        className={style.loader} /> : ((productDetails && productDetails?.reviews.length < productDetails?.reviewsCount) &&
                                        <AppButton type="text" disabled={false}
                                                   additionalClassNames={style.readMoreReviewsBtn}
                                                   children={<><p>{/*Read All Reviews*/}Load more</p><DropdownCaretIcon
                                                       color="#666666" /></>}
                                                   onClick={handleLoadMoreReviewsButtonClick}
                                        />)}
                            </div>
                        </>}
                </div>
            </section>
            {isEditProductPopupOpen && productDetails &&
                <ModalWrapper onClick={() => setIsEditProductPopupOpen(false)}>
                    <AddNewOrEditProductPopup
                        setIsModalOpen={setIsEditProductPopupOpen}
                        isEdit={true}
                        productFormData={{
                            id: productDetails.id,
                            title: productDetails.title,
                            description: productDetails.description,
                            url: productDetails.url,
                            lists: [],
                            file: null
                        }}
                        imageOfExistingProduct={productDetails.imageUrl}
                        onProductEdit={handleUpdateProductDetailsAfterEdit}
                    />
                </ModalWrapper>
            }
        </>
    );
};

export default ProductPage;