import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import classnames from "classnames";
import AppButton from "../../../common/AppButton/AppButton";
import Avatar from "../../../common/Avatar/Avatar";
import HamburgerIcon from "../../../assets/icons/HamburgerIcon";
import ModalWrapper from "../../../common/ModalWrapper/ModalWrapper";
import AddNewOrEditProductPopup from "../../../common/AddNewOrEditProductPopup/AddNewOrEditProductPopup";
import SandwichPopup from "../../../common/SandwichPopup/SandwichPopup";
import UploadListPopup from "../../../common/UploadListPopup/UploadListPopup";
import { SetStateType } from "../../../utils/types";
import style from "./AuthorizedContainer.module.scss";

interface IProps {
    avatarUrl: string | null;
    setIsMobileMenuOpen: SetStateType<boolean>;
}

const AuthorizedContainer = ({ avatarUrl, setIsMobileMenuOpen }: IProps) => {
    const navigate = useNavigate();
    const [isSandwichPopupOpen, setIsSandwichPopupOpen] = useState<boolean>(false);
    const [isAddNewOrEditProductPopupOpen, setIsAddNewOrEditProductPopupOpen] = useState<boolean>(false);
    const [isUploadListPopupOpen, setIsUploadListPopupOpen] = useState<boolean>(false);

    const handleBurgerMenuClick = () => {
        setIsMobileMenuOpen(prevState => !prevState);
    };

    const handleAvatarButtonClick = () => {
        navigate("/my_profile");
    };

    const handleUploadListClick = () => {
        setIsUploadListPopupOpen(true);
    };

    return (
        <>
            <AppButton children={<p>+<span> New Product</span></p>} type="contained" disabled={false}
                       additionalClassNames={style.newFileButton}
                       onClick={() => setIsSandwichPopupOpen(true)}
            />
            <AppButton type="text" disabled={false}
                       additionalClassNames={classnames(style.avatarButton, style.hiddenAvatarButton)}
                       children={<Avatar src={avatarUrl || ""} />}
                       onClick={handleAvatarButtonClick}
            />
            <button className={style.hamburger} onClick={handleBurgerMenuClick}>
                <HamburgerIcon />
            </button>
            {isSandwichPopupOpen &&
                <SandwichPopup type="new_product"
                               handleSandwichPopupClose={() => setIsSandwichPopupOpen(false)}
                               handleFirstButtonClick={() => setIsAddNewOrEditProductPopupOpen(true)}
                               handleSecondButtonClick={handleUploadListClick} />}
            {isAddNewOrEditProductPopupOpen &&
                <ModalWrapper onClick={() => setIsAddNewOrEditProductPopupOpen(false)}>
                    <AddNewOrEditProductPopup setIsModalOpen={setIsAddNewOrEditProductPopupOpen} isEdit={false} />
                </ModalWrapper>
            }
            {isUploadListPopupOpen &&
                <ModalWrapper onClick={() => setIsUploadListPopupOpen(false)}>
                    <UploadListPopup setIsUploadListPopupOpen={setIsUploadListPopupOpen} />
                </ModalWrapper>}
        </>
    );
};

export default AuthorizedContainer;