import React from "react";
import TrashIcon from "../../assets/icons/TrashIcon";
import AppButton from "../AppButton/AppButton";
import Loader from "../Loader/Loader";
import style from "./RemovePopup.module.scss";

interface IProps {
    type: "product" | "list" | "product_from_list";
    onCancel: () => void;
    onConfirm: (id?: string) => Promise<void>;
    isLoading: boolean;
}

const RemovePopup = ({ type, onCancel, onConfirm, isLoading }: IProps) => {

    const handleContainerClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
    };

    return (
        <div className={style.container} onClick={handleContainerClick}>
            <div className={style.info}>
                <TrashIcon color="#E00000" className={style.icon} />
                <h2>
                    {type === "product" && "Remove Product?"}
                    {type === "list" && "Remove List?"}
                    {type === "product_from_list" && "Remove Product from List?"}
                </h2>
                <p>
                    {type === "product" && "Are you sure you want to remove this product? This product will be removed permanently."}
                    {type === "list" && "Are you sure you want to remove this list? This list will be removed."}
                    {type === "product_from_list" && "Are you sure you want to remove this product from this list? This product will be removed from the list."}
                </p>
            </div>
            <div className={style.buttons}>
                <AppButton type="text" disabled={isLoading} additionalClassNames={style.cancelButton}
                           children={<>Cancel</>}
                           onClick={onCancel}
                />
                <AppButton type="text" disabled={isLoading} additionalClassNames={style.confirmButton}
                           children={<>{isLoading ? <Loader className={style.loader} /> : "Yes, Remove"}</>}
                           onClick={onConfirm}
                />
            </div>
        </div>
    );
};

export default RemovePopup;