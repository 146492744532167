import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import classnames from "classnames";
import { addDoc, collection, doc, DocumentReference, DocumentSnapshot, getDoc } from "firebase/firestore";
import { useAuth } from "../../hooks/hooks";
import AppButton from "../AppButton/AppButton";
import TrashIcon from "../../assets/icons/TrashIcon";
import Loader from "../Loader/Loader";
import { nFormatter } from "../../utils/functions";
import { db } from "../../utils/firebase_config";
import { listPrivacyOptionType } from "../../utils/types";
import style from "./UploadListPopup.module.scss";

interface IProps {
    setIsUploadListPopupOpen: (value: boolean) => void;
}

interface IFormData {
    file: File | null;
    name: string | null;
    accessStatus: listPrivacyOptionType | null;
    productsIds: string[];
}

const initialFormData: IFormData = {
    file: null,
    name: null,
    accessStatus: null,
    productsIds: []
};

const UploadListPopup = ({ setIsUploadListPopupOpen }: IProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const { user } = useAuth();
    const userRef = doc(db, "users", user.id);
    const listsRef = collection(db, "lists");
    const [isProductsExist, setIsProductsExist] = useState<boolean>(true);
    const [isCheckingIfProductsExist, setIsCheckingIfProductsExist] = useState<boolean>(false);
    const [isUploadLoading, setIsUploadLoading] = useState<boolean>(false);

    const formik = useFormik({
        initialValues: {
            ...initialFormData
        },
        validationSchema: Yup.object({
            file: Yup.mixed().required(),
            name: Yup.string().required(),
            accessStatus: Yup.string().oneOf(["public", "private"])
        }),
        onSubmit: async (values) => {
            setIsUploadLoading(true);

            const { name, accessStatus, productsIds } = values;

            const productsReferences: DocumentReference[] = productsIds.map(id => doc(db, "products", id));

            await addDoc(listsRef, {
                author: userRef,
                name,
                accessStatus,
                imageUrl: null,
                imageRelativePath: null,
                productsReferences
            });

            setIsUploadLoading(false);
            setIsUploadListPopupOpen(false);
        }
    });

    useEffect(() => {
        if (formik.values.file) {
            parseJsonFile(formik.values.file)
                .then(data => {
                    const { name, accessStatus, productsIds = [] } = data as Omit<IFormData, "file">;
                    formik.setFieldValue("name", name);
                    formik.setFieldValue("accessStatus", accessStatus);
                    formik.setFieldValue("productsIds", productsIds);
                })
                .catch(error => console.log("error", error));
        } else {
            formik.setFieldValue("name", null);
            formik.setFieldValue("accessStatus", null);
            formik.setFieldValue("productsIds", []);
        }
    }, [formik.values.file]);

    useEffect(() => {
        formik.validateField("name");
    }, [formik.values.name]);

    useEffect(() => {
        formik.validateField("accessStatus");
    }, [formik.values.accessStatus]);

    useEffect(() => {
        setIsCheckingIfProductsExist(true);

        const checkProductsPromises: Promise<DocumentSnapshot>[] = [];

        formik.values.productsIds.forEach(el => {
            const productRef = doc(db, "products", el);
            checkProductsPromises.push(getDoc(productRef));
        });

        Promise.all(checkProductsPromises).then(values => {
            setIsProductsExist(values.every(docSnap => docSnap.exists()));
            setIsCheckingIfProductsExist(false);
        });
    }, [formik.values.productsIds]);

    const handleContainerClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            formik.setFieldValue("file", e.target.files[0]);
        }
    };

    const parseJsonFile = async (file: File) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onload = (event) => {
                if (event.target && event.target.result) {
                    resolve(JSON.parse(event.target.result as string));
                }
            };
            fileReader.onerror = error => reject(error);
            fileReader.readAsText(file);
        });
    };

    const handleFileRemove = () => {
        formik.setFieldValue("file", null);
        if (inputRef.current) {
            inputRef.current.value = "";
        }
    };

    return (
        <div className={style.container} onClick={handleContainerClick}>
            <h2>Upload List</h2>
            <label htmlFor="file"
                   className={classnames(style.dragAndDrop, formik.values.file && style.dragAndDropDisabled, formik.touched.file && formik.errors.file && style.notAllowedFileInputValue)}>
                {formik.values.file ?
                    <>
                        <p className={style.selectedFileName}>{formik.values.file.name}</p>
                        <AppButton type="text" disabled={false} additionalClassNames={style.removeFileButton}
                                   children={<TrashIcon color="#E00000" />}
                                   onClick={handleFileRemove}
                        />
                    </> :
                    <p>Choose list's file</p>}
            </label>
            <input ref={inputRef} id="file" type="file" accept=".json"
                   className={style.fileInput}
                   onChange={handleFileChange}
                   onBlur={formik.handleBlur}
                   disabled={Boolean(formik.values.file)}
            />
            {formik.values.file && <>
                <p className={style.name}>Name:{" "}
                    <span className={classnames(formik.errors.name && style.notAllowedFieldValue)}>
                        {String(formik.values.name)}
                    </span>
                </p>
                <p>Access status:{" "}
                    <span className={classnames(formik.errors.accessStatus && style.notAllowedFieldValue)}>
                        {String(formik.values.accessStatus)}
                    </span>
                </p>
                <div className={style.products}>
                    Products count: {nFormatter(formik.values.productsIds.length, 1)}
                    {!isProductsExist &&
                        <span className={style.notAllowedFieldValue}>
                        {" "}(Not all products exist)
                        </span>}
                    {isCheckingIfProductsExist && <Loader className={style.loader} />}
                </div>
            </>}
            <AppButton type="contained"
                       disabled={!formik.values.file || Object.keys(formik.errors).length > 0 || isUploadLoading || isCheckingIfProductsExist || !isProductsExist}
                       additionalClassNames={style.uploadButton}
                       children={isUploadLoading ? <Loader className={style.loader} /> : <>Upload list</>}
                       onClick={formik.handleSubmit}
            />
        </div>
    );
};

export default UploadListPopup;