import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { collection, deleteDoc, doc } from "firebase/firestore";
import classnames from "classnames";
import { useAuth } from "../../../../../hooks/hooks";
import AppButton from "../../../../../common/AppButton/AppButton";
import DotsIcon from "../../../../../assets/icons/DotsIcon";
import ModalWrapper from "../../../../../common/ModalWrapper/ModalWrapper";
import SandwichPopup from "../../../../../common/SandwichPopup/SandwichPopup";
import RemovePopup from "../../../../../common/RemovePopup/RemovePopup";
import ReportPopup from "../../../../../common/ReportPopup/ReportPopup";
import AddNewOrEditListPopup from "../../../../../common/AddNewOrEditListPopup/AddNewOrEditListPopup";
import { db } from "../../../../../utils/firebase_config";
import { IList, listPrivacyOptionType } from "../../../../../utils/types";
import style from "./List.module.scss";

interface IProps {
    list: IList;
    isMyProfile: boolean;
    handleListRemove: (id: string) => void;
    handleListUpdate: (id: string, name: string, accessStatus: listPrivacyOptionType, imageUrl: string | null) => void;
}

const List = ({ list, isMyProfile, handleListRemove, handleListUpdate }: IProps) => {
    const navigate = useNavigate();
    const { user, isAuthorized } = useAuth();
    const userRef = isAuthorized ? doc(db, "users", user.id) : null;
    const listsRef = collection(db, "lists");
    const listRef = doc(db, "lists", list.id);
    const [isSandwichPopupOpen, setIsSandwichPopupOpen] = useState<boolean>(false);
    const [isRemoveListPopupOpen, setIsRemoveListPopupOpen] = useState<boolean>(false);
    const [isRemoveLoading, setIsRemoveLoading] = useState<boolean>(false);
    const [isReportPopupOpen, setIsReportPopupOpen] = useState<boolean>(false);
    const [isEditListPopupOpen, setIsEditListPopupOpen] = useState<boolean>(false);

    const handleContainerClick = () => {
        navigate(`/product_details/${list.productsReferences[0].id}`, {
            state: {
                from: "list",
                listId: list.id
            }
        });
    };

    const handleDotsClick = () => {
        setIsSandwichPopupOpen(true);
    };

    const handleRemoveClick = async () => {
        setIsRemoveLoading(true);
        await deleteDoc(listRef);
        handleListRemove(list.id);
        setIsRemoveLoading(false);
        setIsRemoveListPopupOpen(false);
    };

    const handleDownloadListClick = () => {
        const { name, accessStatus, productsReferences } = list;
        const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
            JSON.stringify({ name, accessStatus, productsIds: productsReferences.map(el => el.id) })
        )}`;
        const link = document.createElement("a");
        link.href = jsonString;
        link.download = `${list.name}.json`;
        link.click();
    };

    return (
        <>
            <div
                className={classnames(style.container, list.productsReferences.length > 0 && style.containerCursorPointer)}
                onClick={list.productsReferences.length > 0 ? handleContainerClick : undefined}>
                {list.imageUrl ? <img src={list.imageUrl} alt="List" /> :
                    <div className={style.listImageSubstitute}><p>List</p></div>}
                <div className={style.text}>
                    <div className={style.header}>
                        <h2>{list.name}</h2>
                        <AppButton type="text" disabled={!isAuthorized} additionalClassNames={style.iconBtn}
                                   children={<DotsIcon color="#A3A3A3" className={style.icon} />}
                                   onClick={handleDotsClick}
                        />
                    </div>
                    <p>{list.productsReferences.length} products</p>
                </div>
                {isSandwichPopupOpen &&
                    <SandwichPopup type={isMyProfile ? "list" : "share_and_report"}
                                   handleSandwichPopupClose={() => setIsSandwichPopupOpen(false)}
                                   handleFirstButtonClick={isMyProfile ? handleDownloadListClick : () => {
                                   }}
                                   handleSecondButtonClick={isMyProfile ? () => setIsEditListPopupOpen(true) : () => setIsReportPopupOpen(true)}
                                   handleThirdButtonClick={() => setIsRemoveListPopupOpen(true)}
                                   additionalClassNames={style.sandwichPopup} />}
            </div>
            {isRemoveListPopupOpen &&
                <ModalWrapper onClick={() => setIsRemoveListPopupOpen(isRemoveLoading)}>
                    <RemovePopup type="list"
                                 onCancel={() => setIsRemoveListPopupOpen(false)}
                                 onConfirm={handleRemoveClick}
                                 isLoading={isRemoveLoading}
                    />
                </ModalWrapper>
            }
            {isReportPopupOpen &&
                <ModalWrapper onClick={() => setIsReportPopupOpen(false)}>
                    <ReportPopup setIsReportPopupOpen={setIsReportPopupOpen} reportType="list" listId={list.id} />
                </ModalWrapper>}
            {isEditListPopupOpen && userRef &&
                <ModalWrapper onClick={() => setIsEditListPopupOpen(false)}>
                    <AddNewOrEditListPopup
                        userRef={userRef}
                        listsRef={listsRef}
                        setIsAddNewOrEditListPopupOpen={setIsEditListPopupOpen}
                        handleListUpdate={handleListUpdate}
                        isEdit={true}
                        listFormData={{ id: list.id, name: list.name, accessStatus: list.accessStatus, file: null }} />
                </ModalWrapper>}
        </>
    );
};

export default List;