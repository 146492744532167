import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import classnames from "classnames";
import { addDoc, collection, doc } from "firebase/firestore";
import { useAuth } from "../../hooks/hooks";
import AppButton from "../AppButton/AppButton";
import Loader from "../Loader/Loader";
import { db } from "../../utils/firebase_config";
import style from "./ReportPopup.module.scss";

interface IProps {
    setIsReportPopupOpen: (value: boolean) => void;
    reportType: "product" | "review" | "user" | "list";
    productId?: string;
    reviewId?: string;
    reportedUserId?: string;
    listId?: string;
}

const ReportPopup = ({ setIsReportPopupOpen, reportType, productId, reviewId, reportedUserId, listId }: IProps) => {
    const { user } = useAuth();
    const reportsRef = collection(db, "reports");
    const userRef = doc(db, "users", user.id);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const formik = useFormik({
        initialValues: {
            text: ""
        }, validationSchema: Yup.object({
            text: Yup.string().required("Required field")
        }),
        onSubmit: async (values) => {
            setIsLoading(true);
            const { text } = values;
            switch (reportType) {
                case "product":
                    if (productId) {
                        const productRef = doc(db, "products", productId);
                        await addDoc(reportsRef, {
                            author: userRef,
                            message: text,
                            reportType,
                            product: productRef
                        });
                    }
                    break;
                case "review":
                    if (productId && reviewId) {
                        const reviewRef = doc(db, `products/${productId}/reviews/${reviewId}`);
                        await addDoc(reportsRef, {
                            author: userRef,
                            message: text,
                            reportType,
                            review: reviewRef
                        });
                    }
                    break;
                case "user":
                    if (reportedUserId) {
                        const reportedUserRef = doc(db, "users", reportedUserId);
                        await addDoc(reportsRef, {
                            author: userRef,
                            message: text,
                            reportType,
                            reportedUser: reportedUserRef
                        });
                    }
                    break;
                case "list":
                    if (listId) {
                        const listRef = doc(db, "lists", listId);
                        await addDoc(reportsRef, {
                            author: userRef,
                            message: text,
                            reportType,
                            list: listRef
                        });
                    }
                    break;
            }
            setIsLoading(false);
            setIsReportPopupOpen(false);
        }
    });

    const handleContainerClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
    };

    return (
        <div className={style.container} onClick={handleContainerClick}>
            <h2>Send a Report</h2>
            <textarea name="text"
                      placeholder="Report"
                      className={classnames(formik.touched.text && formik.errors.text && style.notAllowedFieldValue)}
                      value={formik.values.text}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
            />
            <AppButton type="contained"
                       disabled={isLoading}
                       additionalClassNames={style.button}
                       children={<><p>Send Report</p>{isLoading && <Loader className={style.loader} />}</>}
                       onClick={formik.handleSubmit}
            />
        </div>
    );
};

export default ReportPopup;