import React, { useState } from "react";
import { useAuth } from "../../../hooks/hooks";
import StarIcon from "../../../assets/icons/StarIcon";
import AppButton from "../../../common/AppButton/AppButton";
import ModalWrapper from "../../../common/ModalWrapper/ModalWrapper";
import PostOrEditReviewPopup from "../PostOrEditReviewPopup/PostOrEditReviewPopup";
import { commasFormatter } from "../../../utils/functions";
import { starCountsType } from "../../../utils/types";
import style from "./Ratings.module.scss";

interface IProps {
    isUserProduct: boolean;
    reviewsCount: number;
    averageRating: number;
    starCounts: starCountsType;
    handleAddReview: (rating: number, text: string) => Promise<void>;
    isReviewedByCurrentUser: boolean;
}

const Ratings = ({
                     isUserProduct,
                     averageRating,
                     reviewsCount,
                     starCounts,
                     handleAddReview,
                     isReviewedByCurrentUser
                 }: IProps) => {
    const { isAuthorized } = useAuth();
    const [isPostReviewPopupOpen, setIsPostReviewPopupOpen] = useState<boolean>(false);

    const handleClick = () => {
        setIsPostReviewPopupOpen(true);
    };

    return (<>
            <div className={style.ratings}>
                <div className={style.header}>
                    <h2>Ratings & Reviews</h2>
                    {!isUserProduct && !isReviewedByCurrentUser &&
                        <AppButton type="contained" disabled={!isAuthorized}
                                   additionalClassNames={style.giveReviewButton}
                                   children={<>+ Give Review</>}
                                   onClick={handleClick}
                        />}
                </div>
                <div className={style.inner}>
                    <div className={style.leftSide}>
                        <p className={style.rating}>{averageRating.toFixed(1)}</p>
                        <div>
                            {[...Array(5)].map((el, index) =>
                                <StarIcon key={index} color={index < Math.floor(averageRating) ? "default" : "#EEEEEE"}
                                          className={style.starIcon} />)}
                        </div>
                        <p className={style.reviewsCount}>{commasFormatter(reviewsCount)}</p>
                    </div>
                    <div className={style.rightSide}>
                        {[...Array(5)].map((el, index, arr) =>
                            <div key={index} className={style.ratingWrapper}>
                                <p>{arr.length - index}</p>
                                <div className={style.lineWrapper}>
                                    <div className={style.lineInner}
                                         style={{
                                             width: `${reviewsCount > 0 ? (starCounts[arr.length - index as 1 | 2 | 3 | 4 | 5] / reviewsCount * 100) : 0}%`
                                         }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {isPostReviewPopupOpen &&
                <ModalWrapper onClick={() => setIsPostReviewPopupOpen(false)}>
                    <PostOrEditReviewPopup isEdit={false} handleAddReview={handleAddReview}
                                           setIsPostReviewPopupOpen={setIsPostReviewPopupOpen} />
                </ModalWrapper>}
        </>
    );
};

export default Ratings;