import { useEffect, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { AppDispatch, RootState } from "../store/store";
import { auth, db } from "../utils/firebase_config";

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useAuth = () => {
    const { user } = useAppSelector(state => state.authReducer);
    return {
        user,
        isAuthorized: !!user.id
    };
};

export const useSignInWithPopup = () => {
    const provider = new GoogleAuthProvider();

    return () => signInWithPopup(auth, provider).then(async (result) => {
        const { uid: id, displayName, email, photoURL: avatarUrl } = result.user;
        const userRef = doc(db, "users", id);
        const docSnap = await getDoc(userRef);
        if (!docSnap.exists()) {
            await setDoc(userRef, {
                displayName,
                email,
                avatarUrl,
                type: "influencer",
                followersCount: 0,
                followingsCount: 0,
                bio: "",
                productsCount: 0,
                privateListsCount: 0,
                publicListsCount: 0,
                unreadNotificationsCount: 0,
                _displayName: displayName?.toLowerCase()
            });
        }
    });
};

export const useDebounce = (value: string, delay: number) => {
    const [debouncedValue, setDebouncedValue] = useState<string>(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
};