import React from "react";
import classnames from "classnames";
import style from "./Loader.module.scss";

interface IProps {
    className?: string;
}

const Loader = ({ className = "" }: IProps) => {
    return (
        <div className={classnames(style.container, className)} />
    );
};

export default Loader;