import React from "react";
import classnames from "classnames";
import ListItem from "./ListItem/ListItem";
import { IListReduced } from "../../utils/types";
import style from "./ListOfLists.module.scss";

interface IProps {
    items: IListReduced[];
    selectedItems: IListReduced[];
    handleListsChange: (isAdd: boolean, currentList: IListReduced) => void;
    additionalClassnames: string;
}

const ListOfLists = ({ items, selectedItems, additionalClassnames, handleListsChange }: IProps) => {

    const isChecked = (item: IListReduced) => {
        return selectedItems.some(el => el.id === item.id);
    };

    return (
        <ul className={classnames(style.list, additionalClassnames)} onClick={e => e.stopPropagation()}>
            {items.map(el => <ListItem key={el.id} value={el}
                                       isChecked={isChecked}
                                       handleListsChange={handleListsChange} />)}
        </ul>
    );
};

export default ListOfLists;