import React from "react";

interface IProps {
    className?: string;
}

const CheckboxCheckedIcon = ({ className = "" }: IProps) => {
    return (
        <svg className={className} width="18" height="18" viewBox="0 0 18 18" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1" width="16" height="16" rx="4" fill="#2C56E4" stroke="#2C56E4" strokeWidth="2" />
            <path
                d="M6.70711 8.29289C6.31658 7.90237 5.68342 7.90237 5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.70711L6.70711 8.29289ZM8 11L7.29289 11.7071C7.68342 12.0976 8.31658 12.0976 8.70711 11.7071L8 11ZM12.7071 7.70711C13.0976 7.31658 13.0976 6.68342 12.7071 6.29289C12.3166 5.90237 11.6834 5.90237 11.2929 6.29289L12.7071 7.70711ZM5.29289 9.70711L7.29289 11.7071L8.70711 10.2929L6.70711 8.29289L5.29289 9.70711ZM8.70711 11.7071L12.7071 7.70711L11.2929 6.29289L7.29289 10.2929L8.70711 11.7071Z"
                fill="white" />
        </svg>
    );
};

export default CheckboxCheckedIcon;