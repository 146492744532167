import React from "react";
import { useSignInWithPopup } from "../../../hooks/hooks";
import GoogleIcon from "../../../assets/icons/GoogleIcon";
import style from "./GoogleAuthButton.module.scss";

const GoogleAuthButton = () => {
    const signInWithPopup = useSignInWithPopup();

    return (
        <button className={style.btn} onClick={signInWithPopup}>
            <GoogleIcon />
            <p>Sign in with Google</p>
        </button>
    );
};

export default GoogleAuthButton;