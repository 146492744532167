import React, { useEffect, useState } from "react";
import {
    collection,
    query,
    getDocs,
    where,
    getDoc,
    DocumentSnapshot,
    QuerySnapshot,
    doc,
    limit
} from "firebase/firestore";
import { useAppSelector, useDebounce } from "../../../hooks/hooks";
import NewProductPost from "../../../common/NewProductPost/NewProductPost";
import ProductCard from "./ProductCard/ProductCard";
import InfluencerCard from "./InfluencerCard/InfluencerCard";
import Loader from "../../../common/Loader/Loader";
import { db } from "../../../utils/firebase_config";
import { IUser, IProduct, IInfluencer } from "../../../utils/types";
import style from "./List.module.scss";

interface IProps {
    isAuthorized: boolean;
    user: IUser;
}

const List = ({ isAuthorized, user }: IProps) => {
    const [influencers, setInfluencers] = useState<IInfluencer[]>([]);
    const userRef = user.id ? doc(db, "users", user.id) : null;
    const usersRef = collection(db, "users");
    const { searchQuery } = useAppSelector(state => state.mainReducer);
    const debouncedSearchQuery = useDebounce(searchQuery, 500);
    const influencersQuery = query(usersRef, where("type", "==", "influencer"),
        where("_displayName", ">=", debouncedSearchQuery.toLowerCase()),
        where("_displayName", "<=", debouncedSearchQuery.toLowerCase() + "\uf8ff"));
    const [products, setProducts] = useState<IProduct[]>([]);
    const productsRef = collection(db, "products");
    const productsQuery = query(productsRef,
        where("_title", ">=", debouncedSearchQuery.toLowerCase()),
        where("_title", "<=", debouncedSearchQuery.toLowerCase() + "\uf8ff"),
        limit(20));
    const followingsRef = collection(db, "followings");
    const [isInfluencersLoading, setIsInfluencersLoading] = useState<boolean>(false);
    const [isProductsLoading, setIsProductsLoading] = useState<boolean>(false);

    useEffect(() => {
        setInfluencers([]);
        setProducts([]);
        fetchInfluencers().then(data => setInfluencers(data));
        fetchProducts().then(data => setProducts(data));
    }, [isAuthorized, debouncedSearchQuery]);

    const fetchInfluencers = async () => {
        setIsInfluencersLoading(true);

        const influencersArray: IInfluencer[] = [];
        const querySnapshot = await getDocs(influencersQuery);
        const isFollowedPromises: Promise<QuerySnapshot>[] = [];

        querySnapshot.forEach(doc => {
            const { id } = doc;
            const {
                email,
                displayName,
                avatarUrl,
                followingsCount,
                followersCount,
                productsCount,
                publicListsCount
            } = doc.data();

            if (user.id !== id) {
                influencersArray.push({
                    id,
                    email,
                    displayName,
                    avatarUrl,
                    followingsCount,
                    followersCount,
                    productsCount,
                    publicListsCount,
                    isFollowedByCurrentUser: false
                });
            }

            if (userRef) {
                const isFollowedQuery = query(followingsRef,
                    where("user", "==", userRef),
                    where("followedUser", "==", doc.ref));
                isFollowedPromises.push(getDocs(isFollowedQuery));
            }
        });

        await Promise
            .all(isFollowedPromises)
            .then(values => {
                    values.forEach(snapshot => {
                        snapshot.forEach(doc => {
                            const { followedUser: { id } } = doc.data();
                            influencersArray.forEach((influencer, index) => {
                                if (influencer.id === id) {
                                    influencersArray[index] = { ...influencer, isFollowedByCurrentUser: true };
                                }
                            });
                        });
                    });
                }
            );

        setIsInfluencersLoading(false);

        return influencersArray;
    };

    const fetchProducts = async () => {
        setIsProductsLoading(true);
        let productsArray: IProduct[] = [];
        const querySnapshot = await getDocs(productsQuery);
        const influencerPromises: Promise<DocumentSnapshot<IInfluencer>>[] = [];

        querySnapshot.forEach(doc => {
            const { id } = doc;
            const { title, imageUrl, createdAt, influencer, reviewsCount, averageRating } = doc.data();

            productsArray.push({
                id,
                title,
                imageUrl,
                createdAt,
                reviewsCount,
                averageRating,
                influencer
            });

            influencerPromises.push(getDoc(influencer));
        });

        await Promise
            .all(influencerPromises)
            .then(values => {
                    productsArray = productsArray.map((product, index) => {
                        const { id } = values[index];
                        const {
                            displayName = null,
                            email = null,
                            avatarUrl = null,
                            followersCount = 0,
                            followingsCount = 0,
                            productsCount = 0,
                            publicListsCount = 0,
                            isFollowedByCurrentUser = false
                        } = values[index].data() || {};

                        return ({
                            ...product,
                            influencer: {
                                id,
                                displayName,
                                email,
                                avatarUrl,
                                followersCount,
                                followingsCount,
                                productsCount,
                                publicListsCount,
                                isFollowedByCurrentUser
                            }
                        });
                    });
                }
            );

        setIsProductsLoading(false);

        return productsArray;
    };

    const handleFollowAndUnfollowInfluencer = (influencerID: string) => {
        setInfluencers(prevState => prevState.map(influencer => {
            return influencer.id === influencerID ? {
                ...influencer,
                followersCount: influencer.isFollowedByCurrentUser ? influencer.followersCount - 1 : influencer.followersCount + 1,
                isFollowedByCurrentUser: !influencer.isFollowedByCurrentUser
            } : influencer;
        }));
    };

    const handleProductAdd = (newProduct: IProduct) => {
        setProducts(prevState => [newProduct, ...prevState]);
    };

    return (
        <main className={style.main}>
            {isAuthorized && <NewProductPost
                avatarUrl={user.avatarUrl || ""} onProductAdd={handleProductAdd} />}
            {isInfluencersLoading && isProductsLoading ? <Loader className={style.loader} /> : <>
                {influencers.map(influencer => <InfluencerCard key={influencer.email}
                                                               influencer={influencer}
                                                               handleFollowAndUnfollowInfluencer={handleFollowAndUnfollowInfluencer} />)}
                {products.map(product => <ProductCard key={JSON.stringify(product)} product={product} />)}
            </>}
        </main>
    );
};

export default List;