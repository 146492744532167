import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    arrayRemove,
    deleteDoc,
    doc,
    DocumentReference,
    DocumentSnapshot,
    getDoc,
    updateDoc
} from "firebase/firestore";
import classnames from "classnames";
import { useAuth } from "../../../hooks/hooks";
import ProductItem from "./ProductItem/ProductItem";
import AppButton from "../../../common/AppButton/AppButton";
import DotsIcon from "../../../assets/icons/DotsIcon";
import LocksmithIcon from "../../../assets/icons/LocksmithIcon";
import TrashIcon from "../../../assets/icons/TrashIcon";
import ModalWrapper from "../../../common/ModalWrapper/ModalWrapper";
import SandwichPopup from "../../../common/SandwichPopup/SandwichPopup";
import RemovePopup from "../../../common/RemovePopup/RemovePopup";
import Loader from "../../../common/Loader/Loader";
import ReportPopup from "../../../common/ReportPopup/ReportPopup";
import { db } from "../../../utils/firebase_config";
import { nFormatter } from "../../../utils/functions";
import { IInfluencer, IList, IProduct, IProductWithInfluencerName } from "../../../utils/types";
import style from "./CurrentProductList.module.scss";

interface IProductWithInfluencerRef extends Omit<IProduct, "influencer"> {
    influencer: DocumentReference;
}

interface IProps {
    listId: string;
    productId: string | null;
}

const CurrentProductList = ({ listId, productId }: IProps) => {
    const navigate = useNavigate();
    const { user, isAuthorized } = useAuth();
    const [isMoreOpen, setIsMoreOpen] = useState<boolean>(false);
    const listRef = doc(db, "lists", listId);
    const [list, setList] = useState<IList & { products: IProductWithInfluencerName[] } | null>(null);
    const isUserList = list?.authorId === user.id;
    const currentProduct = productId ? doc(db, "products", productId) : null;
    const [isSandwichPopupOpen, setIsSandwichPopupOpen] = useState<boolean>(false);
    const [isRemoveProductFromListPopupOpen, setIsRemoveProductFromListPopupOpen] = useState<boolean>(false);
    const [isListDataLoading, setIsListDataLoading] = useState<boolean>(false);
    const [isRemoveProductFromListLoading, setIsRemoveProductFromListLoading] = useState<boolean>(false);
    const [isChangeListAccessStatusLoading, setIsChangeListAccessStatusLoading] = useState<boolean>(false);
    const [isRemoveListPopupOpen, setIsRemoveListPopupOpen] = useState<boolean>(false);
    const [isRemoveListLoading, setIsRemoveListLoading] = useState<boolean>(false);
    const [isReportPopupOpen, setIsReportPopupOpen] = useState<boolean>(false);

    useEffect(() => {
        fetchList();
    }, [isAuthorized]);

    const fetchList = async () => {
        setIsListDataLoading(true);

        const productsArray: IProductWithInfluencerName[] = [];
        const productsPromises: Promise<DocumentSnapshot<IProductWithInfluencerRef>>[] = [];

        const listDoc = await getDoc(listRef);
        const {
            name,
            author,
            imageUrl,
            productsReferences,
            accessStatus
        } = listDoc.data() as Omit<IList, "id"> & { author: DocumentReference };

        setList({ id: listId, authorId: author.id, name, imageUrl, productsReferences, products: [], accessStatus });

        productsReferences.forEach(productID => productsPromises.push(getDoc(productID) as Promise<DocumentSnapshot<IProductWithInfluencerRef>>));

        const influencersDisplayNamesPromises: Promise<DocumentSnapshot<IInfluencer>>[] = [];

        await Promise
            .all(productsPromises)
            .then(values => {
                    values.forEach(value => {
                        const { id } = value;
                        const {
                            title,
                            imageUrl,
                            reviewsCount,
                            averageRating,
                            influencer
                        } = value.data() as IProductWithInfluencerRef;

                        productsArray.push({
                            id,
                            title,
                            imageUrl,
                            reviewsCount,
                            averageRating,
                            influencerDisplayName: ""
                        });

                        influencersDisplayNamesPromises.push(getDoc(influencer) as Promise<DocumentSnapshot<IInfluencer>>);
                    });
                }
            );

        await Promise
            .all(influencersDisplayNamesPromises)
            .then(values => {
                values.forEach((value, index) => {
                    const { displayName } = value.data() as IInfluencer;
                    productsArray[index].influencerDisplayName = displayName || "";
                });
            });

        setList(prevState => prevState ? { ...prevState, products: productsArray } : prevState);

        setIsListDataLoading(false);
    };

    const handleRemoveProductFromList = async () => {
        if (currentProduct && list) {
            setIsRemoveProductFromListLoading(true);
            await updateDoc(listRef, {
                productsReferences: arrayRemove(currentProduct)
            });
            setList(prevState => prevState ? {
                ...prevState,
                products: prevState.products.filter(el => el.id !== productId),
                productsReferences: prevState.productsReferences.filter(el => el.id !== productId)
            } : prevState);
            setIsRemoveProductFromListLoading(false);
            setIsRemoveProductFromListPopupOpen(false);
            const nextProductToNavigate = list.products.filter(el => el.id !== productId)[0];
            if (nextProductToNavigate) {
                navigate(`/product_details/${nextProductToNavigate.id}`, {
                    replace: true,
                    state: {
                        from: "list",
                        listId: list.id
                    }
                });
            } else {
                navigate(`/my_profile`, { replace: true });
            }
        }
    };

    const handleLocksmithIconClick = async () => {
        setIsChangeListAccessStatusLoading(true);
        if (list) {
            const newAccessStatus = list.accessStatus === "private" ? "public" : "private";
            await updateDoc(listRef, {
                accessStatus: newAccessStatus
            });
            setList({ ...list, accessStatus: newAccessStatus });
        }
        setIsChangeListAccessStatusLoading(false);
    };

    const handleRemoveList = async () => {
        setIsRemoveListLoading(true);
        await deleteDoc(listRef);
        setIsRemoveListLoading(false);
        setIsRemoveListPopupOpen(false);
        navigate(`/my_profile`, { replace: true });
    };

    return (<>
            <div className={style.wrapper}>
                <div className={classnames(style.container, isMoreOpen && style.openedContainer)}>
                    {isListDataLoading ? <Loader className={style.loader} /> :
                        <>
                            <header>
                                <div className={style.text}>
                                    <div className={style.h2Wrapper}>
                                        <h2>{list?.name}</h2>
                                        {user.id === list?.authorId && (isChangeListAccessStatusLoading ?
                                            <Loader className={style.locksmithLoader} /> :
                                            <AppButton type="text" disabled={false}
                                                       additionalClassNames={style.locksmithButton}
                                                       children={
                                                           <LocksmithIcon
                                                               className={classnames(style.locksmithIcon, !Boolean(list?.accessStatus === "private") && style.semitransparentLocksmithIcon)} />}
                                                       onClick={handleLocksmithIconClick} />)}
                                    </div>
                                    <p>- {nFormatter(list?.products.length || 0, 0)} products</p>
                                </div>
                                <div className={style.buttons}>
                                    {isUserList &&
                                        <AppButton type="text" disabled={false} additionalClassNames={style.button}
                                                   children={<TrashIcon color="#E00000"
                                                                        className={style.trashIcon} />}
                                                   onClick={() => setIsRemoveListPopupOpen(true)} />}
                                    <AppButton type="text" disabled={!isAuthorized} additionalClassNames={style.button}
                                               children={<DotsIcon color="#A3A3A3" className={style.dotsIcon} />}
                                               onClick={() => setIsSandwichPopupOpen(true)}
                                    />
                                </div>
                                {isSandwichPopupOpen && <SandwichPopup type="share_and_report"
                                                                       handleSandwichPopupClose={() => setIsSandwichPopupOpen(false)}
                                                                       handleFirstButtonClick={() => {
                                                                       }}
                                                                       handleSecondButtonClick={() => setIsReportPopupOpen(true)} />}
                            </header>
                            <div className={classnames(style.list, isMoreOpen && style.openedList)}>
                                {list?.products.map(product => <ProductItem key={product.id} product={product}
                                                                            listId={list?.id} />)}
                            </div>
                            <AppButton type="text" disabled={false} additionalClassNames={style.showMore}
                                       children={<>{isMoreOpen ? "Hide more" : "Show more"}</>}
                                       onClick={() => setIsMoreOpen(prev => !prev)} />
                        </>
                    }
                </div>
                {isUserList && !isListDataLoading && list?.products.length > 0 &&
                    <AppButton type="text" disabled={false} additionalClassNames={style.removeBtn}
                               children={<>
                                   <TrashIcon color="#E00000" />
                                   <p className={style.redText}>Remove Product from List</p>
                               </>}
                               onClick={() => setIsRemoveProductFromListPopupOpen(true)} />}
            </div>
            {isRemoveProductFromListPopupOpen &&
                <ModalWrapper onClick={() => setIsRemoveProductFromListPopupOpen(isRemoveProductFromListLoading)}>
                    <RemovePopup
                        type="product_from_list"
                        onCancel={() => setIsRemoveProductFromListPopupOpen(false)}
                        onConfirm={handleRemoveProductFromList}
                        isLoading={isRemoveProductFromListLoading} />
                </ModalWrapper>}
            {isRemoveListPopupOpen &&
                <ModalWrapper onClick={() => setIsRemoveListPopupOpen(isRemoveListLoading)}>
                    <RemovePopup type="list"
                                 onCancel={() => setIsRemoveListPopupOpen(false)}
                                 onConfirm={handleRemoveList}
                                 isLoading={isRemoveListLoading}
                    />
                </ModalWrapper>
            }
            {isReportPopupOpen &&
                <ModalWrapper onClick={() => setIsReportPopupOpen(false)}>
                    <ReportPopup setIsReportPopupOpen={setIsReportPopupOpen} reportType="list" listId={list?.id} />
                </ModalWrapper>}
        </>
    );
};

export default CurrentProductList;