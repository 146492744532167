import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useAuth } from "../../../../hooks/hooks";
import Avatar from "../../../../common/Avatar/Avatar";
import DotsIcon from "../../../../assets/icons/DotsIcon";
import StarIcon from "../../../../assets/icons/StarIcon";
import AppButton from "../../../../common/AppButton/AppButton";
import ModalWrapper from "../../../../common/ModalWrapper/ModalWrapper";
import SandwichPopup from "../../../../common/SandwichPopup/SandwichPopup";
import SelectLists from "../../../../common/SelectLists/SelectLists";
import ReportPopup from "../../../../common/ReportPopup/ReportPopup";
import { nFormatter } from "../../../../utils/functions";
import { IProduct } from "../../../../utils/types";
import style from "./ProductCard.module.scss";

interface IProps {
    product: IProduct;
}

const ProductCard = ({ product }: IProps) => {
    const navigate = useNavigate();
    const { user, isAuthorized } = useAuth();
    const [isSandwichPopupOpen, setIsSandwichPopupOpen] = useState<boolean>(false);
    const [isAddToListsPopupOpen, setIsAddToListsPopupOpen] = useState<boolean>(false);
    const [isReportPopupOpen, setIsReportPopupOpen] = useState<boolean>(false);

    const handleContainerOrAuthorClick = (to: string) => {
        navigate(to, { state: { from: "home" } });
    };

    const handleDotsClick = () => {
        setIsSandwichPopupOpen(true);
    };

    return (
        <div className={style.container} onClick={() => handleContainerOrAuthorClick(`/product_details/${product.id}`)}>
            <div className={style.header}>
                <div className={style.influencerInfo}>
                    <Avatar src={product.influencer?.avatarUrl || ""} />
                    <div className={style.influencerText}>
                        <h2 onClick={(e) => {
                            e.stopPropagation();
                            handleContainerOrAuthorClick(product.influencer?.id === user.id ? "/my_profile" : `/user_profile/${product.influencer?.id}`);
                        }}>{product.influencer?.displayName}</h2>
                        <h3>{moment(product.createdAt.toDate()).calendar()}</h3>
                    </div>
                </div>
                <AppButton type="text" disabled={!isAuthorized} additionalClassNames={style.dotsButton}
                           children={<DotsIcon color="#A3A3A3" />}
                           onClick={handleDotsClick}
                />
                {isSandwichPopupOpen &&
                    <SandwichPopup type="product"
                                   handleSandwichPopupClose={() => setIsSandwichPopupOpen(false)}
                                   handleFirstButtonClick={() => setIsAddToListsPopupOpen(true)}
                                   handleThirdButtonClick={() => setIsReportPopupOpen(true)} />}
            </div>
            <div className={style.content}>
                <img
                    src={product.imageUrl}
                    alt="Product" className={style.productImg} />
                <div className={style.productText}>
                    <h2>{product.title}</h2>
                    <div className={style.reviews}>
                        <StarIcon />
                        <p>({product.averageRating.toFixed(1)}) - {nFormatter(product.reviewsCount, 1)} Reviews</p>
                    </div>
                </div>
            </div>
            {isAddToListsPopupOpen &&
                <ModalWrapper onClick={() => setIsAddToListsPopupOpen(false)}>
                    <SelectLists productId={product.id} setIsAddToListsPopupOpen={setIsAddToListsPopupOpen}
                                 isFromAddOrEditProductPopup={false} />
                </ModalWrapper>
            }
            {isReportPopupOpen &&
                <ModalWrapper onClick={() => setIsReportPopupOpen(false)}>
                    <ReportPopup setIsReportPopupOpen={setIsReportPopupOpen} reportType="product"
                                 productId={product.id} />
                </ModalWrapper>}
        </div>
    );
};


export default ProductCard;