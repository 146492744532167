import React from "react";

interface IProps {
    className?: string;
}

const CheckboxUncheckedIcon = ({ className = "" }: IProps) => {
    return (
        <svg className={className} width="18" height="18" viewBox="0 0 18 18" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1" width="16" height="16" rx="4" stroke="#A9ACB8" strokeWidth="2" />
        </svg>
    );
};

export default CheckboxUncheckedIcon;