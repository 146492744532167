import React, { useEffect } from "react";
import style from "./ModalWrapper.module.scss";

interface IProps {
    children: React.ReactNode;
    onClick: () => void;
}

const ModalWrapper = ({ children, onClick }: IProps) => {

    useEffect(() => {
        document.body.style.overflowY = "hidden";
        return () => {
            document.body.style.overflowY = "scroll";
        };
    }, []);

    return (
        <div className={style.container} onClick={(e) => {
            e.stopPropagation();
            onClick();
        }}>
            {children}
        </div>
    );
};

export default ModalWrapper;