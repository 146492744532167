import React from "react";

const GoogleIcon = () => {
    return (
        <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M25.7688 12.0693H24.8291V12.0208H14.3291V16.6875H20.9225C19.9606 19.4041 17.3758 21.3542 14.3291 21.3542C10.4633 21.3542 7.32908 18.2199 7.32908 14.3542C7.32908 10.4884 10.4633 7.35417 14.3291 7.35417C16.1135 7.35417 17.7369 8.02733 18.973 9.12692L22.2729 5.827C20.1892 3.88508 17.4021 2.6875 14.3291 2.6875C7.88616 2.6875 2.66241 7.91125 2.66241 14.3542C2.66241 20.7971 7.88616 26.0208 14.3291 26.0208C20.772 26.0208 25.9957 20.7971 25.9957 14.3542C25.9957 13.5719 25.9152 12.8083 25.7688 12.0693Z"
                fill="#FFC107" />
            <path
                d="M4.00763 8.92392L7.84071 11.735C8.87788 9.16717 11.3897 7.35417 14.3291 7.35417C16.1135 7.35417 17.737 8.02733 18.973 9.12692L22.273 5.827C20.1893 3.88508 17.4021 2.6875 14.3291 2.6875C9.84796 2.6875 5.9618 5.21742 4.00763 8.92392Z"
                fill="#FF3D00" />
            <path
                d="M14.3291 26.0208C17.3426 26.0208 20.0808 24.8676 22.151 22.9922L18.5402 19.9367C17.3295 20.8574 15.8501 21.3554 14.3291 21.3542C11.2946 21.3542 8.71804 19.4192 7.74737 16.719L3.94287 19.6502C5.8737 23.4285 9.79487 26.0208 14.3291 26.0208Z"
                fill="#4CAF50" />
            <path
                d="M25.7689 12.0694H24.8291V12.021H14.3291V16.6877H20.9225C20.4624 17.9806 19.6336 19.1103 18.5384 19.9374L18.5402 19.9362L22.151 22.9917C21.8955 23.2239 25.9958 20.1877 25.9958 14.3543C25.9958 13.5721 25.9153 12.8085 25.7689 12.0694Z"
                fill="#1976D2" />
        </svg>
    );
};

export default GoogleIcon;