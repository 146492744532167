import React from "react";

const PlusIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 5V19" stroke="#081029" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5 12H19" stroke="#081029" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default PlusIcon;