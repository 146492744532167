import React from "react";

interface IProps {
    color?: string;
    className?: string;
}

const StarIcon = ({ color = "default", className = "" }: IProps) => {
    return (
        <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M12.375 4C12.5977 4 12.8011 4.12654 12.8996 4.32642L15.1708 8.93229L20.2488 9.66887C20.4693 9.70085 20.6526 9.85542 20.7214 10.0675C20.7902 10.2796 20.7327 10.5124 20.573 10.668L16.8932 14.253L17.7609 19.3154C17.7985 19.5352 17.7082 19.7573 17.5279 19.8883C17.3476 20.0193 17.1086 20.0364 16.9115 19.9325L12.3801 17.5426L7.83805 19.9327C7.64093 20.0365 7.40206 20.0192 7.22189 19.8882C7.04172 19.7571 6.95149 19.5351 6.98914 19.3154L7.85681 14.253L4.17699 10.668C4.01733 10.5124 3.9598 10.2796 4.02862 10.0675C4.09744 9.85542 4.28066 9.70085 4.50117 9.66887L9.5792 8.93229L11.8504 4.32642C11.9489 4.12654 12.1523 4 12.375 4Z"
                  fill={color === "default" ? "#FFBF00" : color} />
        </svg>
    );
};

export default StarIcon;