import React, { useEffect, useState } from "react";
import { collection, DocumentReference, getDocs, query, where } from "firebase/firestore";
import List from "./List/List";
import Loader from "../../../../common/Loader/Loader";
import { db } from "../../../../utils/firebase_config";
import { IInfluencerWithBio, IList, listPrivacyOptionType, SetStateType } from "../../../../utils/types";
import style from "./Lists.module.scss";

interface IProps {
    isMyProfile: boolean;
    userProfileData: IInfluencerWithBio | null;
    userRef: DocumentReference | null;
    userLists: IList[];
    setUserLists: SetStateType<IList[]>;
    handleListRemove: (id: string) => void;
}

const Lists = ({ isMyProfile, userProfileData, userRef, userLists, setUserLists, handleListRemove }: IProps) => {
    const listsRef = collection(db, "lists");
    const userListsQuery = userRef ? query(listsRef, where("author", "==", userRef), where("accessStatus", isMyProfile ? "in" : "==", isMyProfile ? ["public", "private"] : "public")) : null;
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (userProfileData && userProfileData.id) {
            fetchUserLists().then(data => {
                setUserLists(data);
            });
        }
    }, [userProfileData?.id]);

    const fetchUserLists = async () => {
        setIsLoading(true);

        const listsArray: IList[] = [];

        if (userListsQuery) {
            const querySnapshot = await getDocs(userListsQuery);

            querySnapshot.forEach(doc => {
                const { id } = doc;
                const { name, author, imageUrl, productsReferences, accessStatus } = doc.data();
                listsArray.push({
                    id,
                    authorId: author.id,
                    name,
                    imageUrl,
                    productsReferences,
                    accessStatus
                });
            });
        }

        setIsLoading(false);

        return listsArray;
    };

    const handleListUpdate = (id: string, name: string, accessStatus: listPrivacyOptionType, imageUrl: string | null) => {
        setUserLists(prevState => prevState.map(el => el.id === id ? { ...el, name, accessStatus, imageUrl } : el));
    };
    return (
        <div className={style.container}>
            {isLoading ? <Loader className={style.loader} /> :
                userLists.map(list => <List key={list.id} list={list} isMyProfile={isMyProfile}
                                            handleListRemove={handleListRemove}
                                            handleListUpdate={handleListUpdate} />)}
        </div>
    );
};

export default Lists;