import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { arrayRemove, arrayUnion, doc, updateDoc } from "firebase/firestore";
import classnames from "classnames";
import { useAuth } from "../../../hooks/hooks";
import Avatar from "../../../common/Avatar/Avatar";
import StarIcon from "../../../assets/icons/StarIcon";
import DotsIcon from "../../../assets/icons/DotsIcon";
import LikeIcon from "../../../assets/icons/LikeIcon";
import AppButton from "../../../common/AppButton/AppButton";
import ModalWrapper from "../../../common/ModalWrapper/ModalWrapper";
import SandwichPopup from "../../../common/SandwichPopup/SandwichPopup";
import PostOrEditReviewPopup from "../PostOrEditReviewPopup/PostOrEditReviewPopup";
import ReportPopup from "../../../common/ReportPopup/ReportPopup";
import { db } from "../../../utils/firebase_config";
import { IReview } from "../../../utils/types";
import style from "./Review.module.scss";

interface IProps {
    review: IReview;
    productId: string;
    handleProductReviewLikes: (isAddLike: boolean, reviewId: string) => void;
    onEdit: (id: string, newText: string, newRating: number, oldRating: number) => void;
}

const Review = ({ review, productId, handleProductReviewLikes, onEdit }: IProps) => {
    const navigate = useNavigate();
    const { user, isAuthorized } = useAuth();
    const reviewRef = doc(db, `products/${productId}/reviews/${review.id}`);
    const isLiked = review.likes.includes(user.id);
    const [likeButtonDisabled, setLikeButtonDisabled] = useState<boolean>(false);
    const [isSandwichPopupOpen, setIsSandwichPopupOpen] = useState<boolean>(false);
    const [isEditReviewPopupOpen, setIsEditReviewPopupOpen] = useState<boolean>(false);
    const [isReportPopupOpen, setIsReportPopupOpen] = useState<boolean>(false);

    const handleLikeClick = async () => {
        setLikeButtonDisabled(true);
        await updateDoc(reviewRef, {
            likes: isLiked ? arrayRemove(user.id) : arrayUnion(user.id)
        });
        handleProductReviewLikes(!isLiked, review.id);
        setLikeButtonDisabled(false);
    };

    const handleAuthorDisplayNameClick = () => {
        navigate(review.author?.id === user.id ? "/my_profile" : `/user_profile/${review.author?.id}`);
    };

    const handleEditReview = async (newRating: number, newText: string) => {
        await updateDoc(reviewRef, {
            text: newText,
            rating: newRating
        });
        onEdit(review.id, newText, newRating, review.rating);
    };

    return (
        <>
            <div className={style.container}>
                <div className={style.header}>
                    <div className={style.author}>
                        <Avatar src={review.author.avatarUrl || ""} />
                        <div className={style.authorInfo}>
                            <h2 onClick={handleAuthorDisplayNameClick}>{review.author.displayName}</h2>
                            <div className={style.stars}>
                                {[...Array(5)].map((el, index) =>
                                    <StarIcon key={index} color={index < review.rating ? "default" : "#EEEEEE"}
                                              className={style.starIcon} />)}
                            </div>
                        </div>
                    </div>
                    <div className={style.buttons}>
                        <div className={style.like}>
                            <AppButton type="text" disabled={!isAuthorized || likeButtonDisabled}
                                       additionalClassNames={style.btn}
                                       children={
                                           <LikeIcon isContained={isLiked} color={isLiked ? "#2C56E4" : "#292929"} />
                                       }
                                       onClick={handleLikeClick}
                            />
                            <p className={classnames(isLiked && style.likedText)}>{review.likes.length}</p>
                        </div>
                        <AppButton type="text" disabled={!isAuthorized} additionalClassNames={style.dotsButton}
                                   children={<DotsIcon className={style.dotsIcon} />}
                                   onClick={() => setIsSandwichPopupOpen(true)}
                        />
                    </div>
                    {isSandwichPopupOpen && <SandwichPopup
                        type={review.author.id === user.id ? "edit_and_report" : "report"}
                        handleSandwichPopupClose={() => setIsSandwichPopupOpen(false)}
                        handleFirstButtonClick={() => setIsReportPopupOpen(true)}
                        handleSecondButtonClick={() => setIsEditReviewPopupOpen(true)}
                        additionalClassNames={style.sandwichPopup} />}
                </div>
                <p className={style.text}>{review.text}</p>
            </div>
            {isEditReviewPopupOpen &&
                <ModalWrapper onClick={() => setIsEditReviewPopupOpen(false)}>
                    <PostOrEditReviewPopup isEdit={true} handleEditReview={handleEditReview} prevText={review.text}
                                           prevRating={review.rating}
                                           setIsPostReviewPopupOpen={setIsEditReviewPopupOpen} />
                </ModalWrapper>
            }
            {isReportPopupOpen &&
                <ModalWrapper onClick={() => setIsReportPopupOpen(false)}>
                    <ReportPopup setIsReportPopupOpen={setIsReportPopupOpen} reportType="review"
                                 productId={productId} reviewId={review.id} />
                </ModalWrapper>}
        </>
    );
};

export default Review;