import React from "react";
import classnames from "classnames";
import style from "./Avatar.module.scss";

interface IProps {
    src: string;
    className?: string;
}

const Avatar = ({ src, className = "" }: IProps) => {
    return (
        <img src={src} alt="Avatar" className={classnames(style.avatar, className)} />
    );
};

export default Avatar;