import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { addDoc, DocumentReference, CollectionReference, updateDoc, doc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { v4 } from "uuid";
import classnames from "classnames";
import { useAuth } from "../../hooks/hooks";
import AppButton from "../AppButton/AppButton";
import DropdownCaretIcon from "../../assets/icons/DropdownCaretIcon";
import Options from "./Options/Options";
import TrashIcon from "../../assets/icons/TrashIcon";
import Loader from "../Loader/Loader";
import { db, storage } from "../../utils/firebase_config";
import { IList, IListReduced, listPrivacyOptionType, SetStateType } from "../../utils/types";
import style from "./AddNewOrEditListPopup.module.scss";

interface IProps {
    userRef: DocumentReference;
    listsRef: CollectionReference;
    setIsAddNewOrEditListPopupOpen: (value: boolean) => void;
    setUserLists?: SetStateType<IListReduced[]>;
    handleListAdd?: (newList: IList) => void;
    handleListUpdate?: (id: string, name: string, accessStatus: listPrivacyOptionType, imageUrl: string | null) => void;
    isEdit: boolean;
    listFormData?: IFormData & { id: string };
}

interface IFormData {
    name: string;
    accessStatus: listPrivacyOptionType;
    file: File | null;
}

const initialFormData: IFormData = {
    name: "",
    accessStatus: "public",
    file: null
};

const AddNewOrEditListPopup = ({
                                   userRef,
                                   listsRef,
                                   setIsAddNewOrEditListPopupOpen,
                                   setUserLists,
                                   handleListAdd,
                                   handleListUpdate,
                                   isEdit,
                                   listFormData
                               }: IProps) => {
    const { user } = useAuth();
    const inputRef = useRef<HTMLInputElement>(null);
    const [isDropdownOpened, setIsDropdownOpened] = useState<boolean>(false);
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const formik = useFormik({
        initialValues: (isEdit && listFormData) ? { ...listFormData } : { ...initialFormData },
        validationSchema: Yup.object({
            name: Yup.string().required("Required field"),
            accessStatus: Yup.string().required("Required field"),
            file: Yup.mixed()
        }),
        onSubmit: async (values: IFormData) => {
            setIsLoading(true);

            const { name, accessStatus, file } = values;

            let imageRelativePath: string | null = null;
            let imageUrl: string | null = null;

            if (file) {
                imageRelativePath = `${v4()}.${file.type.split("/")[1]}`;
                const imageRef = ref(storage, `lists/${imageRelativePath}`);
                await uploadBytes(imageRef, file);
                imageUrl = await getDownloadURL(imageRef);
            }

            if (isEdit && listFormData) {
                const listRef = doc(db, "lists", listFormData?.id);

                await updateDoc(listRef, {
                    name,
                    accessStatus,
                    imageUrl,
                    imageRelativePath
                });

                handleListUpdate && handleListUpdate(listFormData.id, name, accessStatus, imageUrl);

            } else {
                const newList: Omit<IList, "id" | "authorId"> = {
                    name,
                    imageUrl,
                    productsReferences: [],
                    accessStatus
                };

                const newListDoc = await addDoc(listsRef, {
                    ...newList,
                    author: userRef,
                    imageRelativePath
                });
                setUserLists && setUserLists(prevState => [...prevState, { id: newListDoc.id, name, accessStatus }]);
                handleListAdd && handleListAdd({ ...newList, id: newListDoc.id, authorId: user.id });
            }

            setIsLoading(false);
            setIsAddNewOrEditListPopupOpen(false);
        }
    });

    useEffect(() => {
        if (formik.values.file) {
            setImageUrl(URL.createObjectURL(formik.values.file));
        } else {
            setImageUrl("");
        }
    }, [formik.values.file]);

    const handleContainerClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
    };

    const handleDropdownClick = () => {
        setIsDropdownOpened(prevState => !prevState);
    };

    const handleOptionClick = (value: listPrivacyOptionType) => {
        formik.setFieldValue("accessStatus", value);
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            formik.setFieldValue("file", e.target.files[0]);
        }
    };

    const handleFileRemove = () => {
        formik.setFieldValue("file", null);
        if (inputRef.current) {
            inputRef.current.value = "";
        }
    };

    return (
        <div className={style.container} onClick={handleContainerClick}>
            <h2>{isEdit ? "Edit" : "New"} List</h2>
            <div className={style.buttons}>
                <input name="name" type="text" placeholder="List Name"
                       className={classnames(formik.touched.name && formik.errors.name && style.notAllowedFieldValue)}
                       value={formik.values.name}
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                />
                <div className={style.dropdown} onClick={handleDropdownClick}>
                    <p>{formik.values.accessStatus.charAt(0).toUpperCase() + formik.values.accessStatus.slice(1)}</p>
                    <DropdownCaretIcon color="#A3A3A3" />
                    <Options
                        items={["private", "public"]}
                        isDropdownOpened={isDropdownOpened}
                        onClick={handleOptionClick}
                    />
                </div>
                <label htmlFor="file"
                       className={classnames(style.dragAndDrop, imageUrl && style.dragAndDropDisabled, formik.touched.file && formik.errors.file && style.notAllowedFileInputValue)}>
                    {imageUrl ?
                        <>
                            <img src={imageUrl} alt="Selected file" />
                            <AppButton type="text" disabled={false} additionalClassNames={style.removeImageButton}
                                       children={<TrashIcon color="#E00000" />}
                                       onClick={handleFileRemove}
                            />
                        </> :
                        <p>+ Add Image</p>}
                </label>
                <input ref={inputRef} id="file" type="file" accept="image/*"
                       className={style.fileInput}
                       onChange={handleFileChange}
                       onBlur={formik.handleBlur}
                       disabled={Boolean(imageUrl)}
                />
            </div>
            <AppButton type="contained" disabled={isLoading} additionalClassNames={""}
                       children={isLoading ?
                           <Loader className={style.loader} /> : <>{isEdit ? "Edit" : "+ Create New"} List</>}
                       onClick={formik.handleSubmit}
            />
        </div>
    );
};

export default AddNewOrEditListPopup;