import React, { useState } from "react";
import classnames from "classnames";
import { DocumentReference, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import { useAuth } from "../../../hooks/hooks";
import AppButton from "../../../common/AppButton/AppButton";
import LikeIcon from "../../../assets/icons/LikeIcon";
import DislikeIcon from "../../../assets/icons/DislikeIcon";
import ShareIcon from "../../../assets/icons/ShareIcon";
import SaveIcon from "../../../assets/icons/SaveIcon";
import DotsIcon from "../../../assets/icons/DotsIcon";
import EditIcon from "../../../assets/icons/EditIcon";
import ModalWrapper from "../../../common/ModalWrapper/ModalWrapper";
import SelectLists from "../../../common/SelectLists/SelectLists";
import SandwichPopup from "../../../common/SandwichPopup/SandwichPopup";
import ReportPopup from "../../../common/ReportPopup/ReportPopup";
import { nFormatter } from "../../../utils/functions";
import style from "./Header.module.scss";

interface IProps {
    id: string;
    title: string;
    isUserProduct: boolean;
    likes: string[];
    dislikes: string[];
    handleProductLikes: (isAddLike: boolean) => void;
    handleProductDislikes: (isAddDislike: boolean) => void;
    productRef: DocumentReference;
    setIsEditProductPopupOpen: (value: boolean) => void;
}

const Header = ({
                    id,
                    title,
                    isUserProduct,
                    likes,
                    dislikes,
                    handleProductLikes,
                    handleProductDislikes,
                    productRef,
                    setIsEditProductPopupOpen
                }: IProps) => {
    const { user, isAuthorized } = useAuth();
    const isLiked = likes.includes(user.id);
    const isDisliked = dislikes.includes(user.id);
    const [likeIsLoading, setLikeIsLoading] = useState<boolean>(false);
    const [dislikeIsLoading, setDislikeIsLoading] = useState<boolean>(false);
    const [isAddToListsPopupOpen, setIsAddToListsPopupOpen] = useState<boolean>(false);
    const [isSandwichPopupOpen, setIsSandwichPopupOpen] = useState<boolean>(false);
    const [isReportPopupOpen, setIsReportPopupOpen] = useState<boolean>(false);

    const handleLikeClick = async () => {
        setLikeIsLoading(true);
        await updateDoc(productRef, {
            likes: isLiked ? arrayRemove(user.id) : arrayUnion(user.id)
        });
        handleProductLikes(!isLiked);
        setLikeIsLoading(false);
    };

    const handleDislikeClick = async () => {
        setDislikeIsLoading(true);
        await updateDoc(productRef, {
            dislikes: isDisliked ? arrayRemove(user.id) : arrayUnion(user.id)
        });
        handleProductDislikes(!isDisliked);
        setDislikeIsLoading(false);
    };

    const handleSaveClick = () => {
        setIsAddToListsPopupOpen(true);
    };

    const handleEditClick = () => {
        setIsEditProductPopupOpen(true);
    };

    return (
        <>
            <div className={style.header}>
                <h2>{title}</h2>
                <div className={style.buttons}>
                    <AppButton type="text" disabled={!isAuthorized || likeIsLoading} additionalClassNames={style.button}
                               children={<>
                                   <LikeIcon color={isLiked ? "#2C56E4" : "#292929"} isContained={isLiked} />
                                   <p className={classnames(isLiked && style.isLikedOrDisliked)}>{nFormatter(likes.length, 0)}</p>
                               </>}
                               onClick={handleLikeClick}
                    />
                    <AppButton type="text" disabled={!isAuthorized || dislikeIsLoading}
                               additionalClassNames={style.button}
                               children={<>
                                   <DislikeIcon color={isDisliked ? "#2C56E4" : "#292929"} isContained={isDisliked} />
                                   <p className={classnames(isDisliked && style.isLikedOrDisliked)}>Dislike</p>
                               </>}
                               onClick={handleDislikeClick}
                    />
                    <AppButton type="text" disabled={!isAuthorized} additionalClassNames={style.button}
                               children={<><ShareIcon color="#292929" /><p>Share</p></>}
                               onClick={handleSaveClick}
                    />
                    <AppButton type="text" disabled={!isAuthorized} additionalClassNames={style.button}
                               children={isUserProduct ?
                                   <>
                                       <EditIcon />
                                       <p>Edit</p>
                                   </> :
                                   <>
                                       <SaveIcon color="#292929" />
                                       <p>Save</p>
                                   </>}
                               onClick={isUserProduct ? handleEditClick : handleSaveClick}
                    />
                    <AppButton type="text" disabled={!isAuthorized} additionalClassNames={style.button}
                               children={<DotsIcon color="#292929" />}
                               onClick={() => setIsSandwichPopupOpen(true)} />
                    {isSandwichPopupOpen && <SandwichPopup
                        type="report"
                        handleFirstButtonClick={() => setIsReportPopupOpen(true)}
                        handleSandwichPopupClose={() => setIsSandwichPopupOpen(false)} />}
                </div>
            </div>
            {isAddToListsPopupOpen &&
                <ModalWrapper onClick={() => setIsAddToListsPopupOpen(false)}>
                    <SelectLists productId={id} setIsAddToListsPopupOpen={setIsAddToListsPopupOpen}
                                 isFromAddOrEditProductPopup={false} />
                </ModalWrapper>
            }
            {isReportPopupOpen &&
                <ModalWrapper onClick={() => setIsReportPopupOpen(false)}>
                    <ReportPopup setIsReportPopupOpen={setIsReportPopupOpen} reportType="product" productId={id} />
                </ModalWrapper>}
        </>
    );
};

export default Header;