import React from "react";
import classnames from "classnames";
import { listPrivacyOptionType } from "../../../utils/types";
import style from "./Options.module.scss";

interface IProps {
    items: listPrivacyOptionType[];
    isDropdownOpened: boolean;
    onClick: (value: listPrivacyOptionType) => void;
}

const Options = ({ items, isDropdownOpened, onClick }: IProps) => {
    return (
        <ul className={classnames(style.list, isDropdownOpened && style.opened)}>
            {items.map(el => <li key={el} onClick={() => onClick(el)}>{el.charAt(0).toUpperCase() + el.slice(1)}</li>)}
        </ul>
    );
};

export default Options;