import React from "react";
import SearchIcon from "../../../../../assets/icons/SearchIcon";
import style from "./Search.module.scss";

const Search = () => {
    return (
        <div className={style.container}>
            <h2>Recommendations</h2>
            <div
                className={style.search}>
                <SearchIcon color="#A3A3A3" />
                <input type="search" placeholder="Search products..." />
            </div>
        </div>
    );
};

export default Search;