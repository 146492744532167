import React from "react";
import classnames from "classnames";
import AppButton from "../../../../common/AppButton/AppButton";
import { nFormatter } from "../../../../utils/functions";
import { SetStateType, toggleType } from "../../../../utils/types";
import style from "./Toggle.module.scss";

interface IProps {
    toggleValue: toggleType;
    setToggleValue: SetStateType<toggleType>;
    productsCount: number;
    listsCount: number;
}

const Toggle = ({ toggleValue, setToggleValue, productsCount, listsCount }: IProps) => {
    return (
        <div className={style.container}>
            <AppButton type="text" disabled={false} additionalClassNames={style.button}
                       onClick={() => setToggleValue("Products")}
                       children={<h2>
                           <span
                               className={classnames(toggleValue === "Products" && style.active)}>Products</span> ({nFormatter(productsCount, 0)})
                       </h2>} />
            <AppButton type="text" disabled={false} additionalClassNames={style.button}
                       onClick={() => setToggleValue("Lists")}
                       children={<h2>
                           <span
                               className={classnames(toggleValue === "Lists" && style.active)}>Lists</span> ({nFormatter(listsCount, 0)})
                       </h2>} />
        </div>
    );
};

export default Toggle;