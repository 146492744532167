import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../hooks/hooks";
import AppButton from "../../../../common/AppButton/AppButton";
import DotsIcon from "../../../../assets/icons/DotsIcon";
import StarIcon from "../../../../assets/icons/StarIcon";
import SandwichPopup from "../../../../common/SandwichPopup/SandwichPopup";
import ModalWrapper from "../../../../common/ModalWrapper/ModalWrapper";
import ReportPopup from "../../../../common/ReportPopup/ReportPopup";
import { nFormatter } from "../../../../utils/functions";
import { IProductWithInfluencerName } from "../../../../utils/types";
import style from "./ProductItem.module.scss";

interface IProps {
    product: IProductWithInfluencerName;
    listId: string;
}

const ProductItem = ({ product, listId }: IProps) => {
    const navigate = useNavigate();
    const { isAuthorized } = useAuth();
    const [isSandwichPopupOpen, setIsSandwichPopupOpen] = useState<boolean>(false);
    const [isReportPopupOpen, setIsReportPopupOpen] = useState<boolean>(false);

    const handleClick = () => {
        navigate(`/product_details/${product.id}`, {
            state: {
                from: "list",
                listId
            }
        });
    };

    return (
        <div className={style.container} onClick={handleClick}>
            <img
                src={product.imageUrl}
                alt="Product" />
            <div className={style.info}>
                <h2>{product.title}</h2>
                <p><StarIcon />({product.averageRating.toFixed(1)}) - {nFormatter(product.reviewsCount, 1)} Reviews
                </p>
                <h3>{product.influencerDisplayName}</h3>
            </div>
            <AppButton type="text" disabled={!isAuthorized} additionalClassNames={style.dotsBtn}
                       children={<DotsIcon color="#A3A3A3" className={style.icon} />}
                       onClick={() => setIsSandwichPopupOpen(true)}
            />
            {isSandwichPopupOpen &&
                <SandwichPopup type="share_and_report"
                               handleSandwichPopupClose={() => setIsSandwichPopupOpen(false)}
                               handleFirstButtonClick={() => {
                               }}
                               handleSecondButtonClick={() => setIsReportPopupOpen(true)}
                               additionalClassNames={style.sandwichPopup} />}
            {isReportPopupOpen &&
                <ModalWrapper onClick={() => setIsReportPopupOpen(false)}>
                    <ReportPopup setIsReportPopupOpen={setIsReportPopupOpen} reportType="product"
                                 productId={product.id} />
                </ModalWrapper>}
        </div>
    );
};

export default ProductItem;