import React, { useEffect, useState } from "react";
import { addDoc, collection, deleteDoc, doc, getDocs, query, QueryDocumentSnapshot, where } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { useAuth, useSignInWithPopup } from "../../../hooks/hooks";
import Avatar from "../../../common/Avatar/Avatar";
import AppButton from "../../../common/AppButton/AppButton";
import FollowIcon from "../../../assets/icons/FollowIcon";
import { db } from "../../../utils/firebase_config";
import { nFormatter } from "../../../utils/functions";
import { IInfluencer } from "../../../utils/types";
import style from "./Influencer.module.scss";

interface IProps {
    influencer: IInfluencer | null | undefined;
    isUserProduct: boolean;
    handleFollowAndUnfollowInfluencer: () => void;
}

const Influencer = ({ influencer, isUserProduct, handleFollowAndUnfollowInfluencer }: IProps) => {
    const navigate = useNavigate();
    const { user, isAuthorized } = useAuth();
    const signInWithPopup = useSignInWithPopup();
    const userDoc = isAuthorized ? doc(db, "users", user.id) : null;
    const followedUserDoc = influencer ? doc(db, "users", influencer.id) : null;
    const followingsRef = collection(db, "followings");
    const followingDocQuery = isAuthorized ? query(followingsRef,
        where("user", "==", userDoc),
        where("followedUser", "==", followedUserDoc)) : null;
    const [followingDoc, setFollowingDoc] = useState<QueryDocumentSnapshot | null>(null);
    const [isFollowLoading, setIsFollowLoading] = useState<boolean>(false);

    useEffect(() => {
        if (followingDocQuery) {
            const fetchFollowingDoc = async () => {
                const snapshot = await getDocs(followingDocQuery);
                snapshot.forEach(doc => {
                    setFollowingDoc(doc);
                });
            };
            fetchFollowingDoc();
        }
    }, [influencer]);

    const handleFollowButtonClick = async () => {
        setIsFollowLoading(true);
        if (influencer) {
            if (influencer.isFollowedByCurrentUser && followingDoc) {
                await deleteDoc(followingDoc.ref);
            } else {
                await addDoc(followingsRef, {
                    user: userDoc,
                    followedUser: followedUserDoc
                });
            }
        }
        handleFollowAndUnfollowInfluencer();
        setIsFollowLoading(false);
    };

    const handleContainerClick = () => {
        navigate(influencer?.id === user.id ? "/my_profile" : `/user_profile/${influencer?.id}`);
    };

    return (
        <div className={style.influencer} onClick={handleContainerClick}>
            <Avatar src={influencer?.avatarUrl || ""} />
            <div className={style.influencerInfo}>
                <h2>{influencer?.displayName}</h2>
                <h3>{nFormatter(influencer?.followersCount || 0, 1)} Followers</h3>
            </div>
            {!isUserProduct && influencer &&
                <AppButton type={influencer.isFollowedByCurrentUser ? "outlined" : "contained"}
                           disabled={isFollowLoading} additionalClassNames={style.button}
                           children={
                               <>
                                   <FollowIcon color={influencer.isFollowedByCurrentUser ? "#2C56E4" : "#FFFFFF"}
                                               className={style.followIcon} />
                                   <p>{influencer.isFollowedByCurrentUser ? "Unfollow" : "Follow"}</p>
                               </>
                           }
                           onClick={isAuthorized ? handleFollowButtonClick : signInWithPopup}
                />}
        </div>
    );
};

export default Influencer;