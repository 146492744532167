import React from "react";

interface IProps {
    color?: string;
    isContained: boolean;
}

const DislikeIcon = ({ color = "default", isContained }: IProps) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill={isContained ? color : "none"}
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7 13.0048V5.00482C7 4.7396 6.89464 4.48525 6.70711 4.29771C6.51957 4.11017 6.26522 4.00482 6 4.00482H4C3.73478 4.00482 3.48043 4.11017 3.29289 4.29771C3.10536 4.48525 3 4.7396 3 5.00482V12.0048C3 12.27 3.10536 12.5244 3.29289 12.7119C3.48043 12.8995 3.73478 13.0048 4 13.0048H7ZM7 13.0048C8.06087 13.0048 9.07828 13.4262 9.82843 14.1764C10.5786 14.9265 11 15.9439 11 17.0048V18.0048C11 18.5352 11.2107 19.044 11.5858 19.419C11.9609 19.7941 12.4696 20.0048 13 20.0048C13.5304 20.0048 14.0391 19.7941 14.4142 19.419C14.7893 19.044 15 18.5352 15 18.0048V13.0048H18C18.5304 13.0048 19.0391 12.7941 19.4142 12.419C19.7893 12.044 20 11.5352 20 11.0048L19 6.00482C18.8562 5.39134 18.5834 4.86457 18.2227 4.50385C17.8619 4.14313 17.4328 3.96799 17 4.00482H10C9.20435 4.00482 8.44129 4.32089 7.87868 4.8835C7.31607 5.4461 7 6.20917 7 7.00482"
                stroke={color === "default" ? "black" : color} strokeWidth="1.5" strokeLinecap="round"
                strokeLinejoin="round" />
        </svg>
    );
};

export default DislikeIcon;