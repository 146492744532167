import React from "react";

interface IProps {
    color?: string;
    className?: string;
}

const ProfileIcon = ({ color = "default", className = "" }: IProps) => {
    return (
        <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.44972 18.6332C5.33053 19.0299 5.55549 19.4481 5.95219 19.5673C6.34888 19.6865 6.76709 19.4615 6.88628 19.0648L5.44972 18.6332ZM10 16L9.99978 16.75H10V16ZM14 16V16.75H14.0003L14 16ZM17.1154 19.0697C17.234 19.4666 17.6518 19.6922 18.0487 19.5736C18.4456 19.455 18.6712 19.0372 18.5526 18.6403L17.1154 19.0697ZM20.25 12C20.25 16.5563 16.5563 20.25 12 20.25V21.75C17.3848 21.75 21.75 17.3848 21.75 12H20.25ZM12 20.25C7.44365 20.25 3.75 16.5563 3.75 12H2.25C2.25 17.3848 6.61522 21.75 12 21.75V20.25ZM3.75 12C3.75 7.44365 7.44365 3.75 12 3.75V2.25C6.61522 2.25 2.25 6.61522 2.25 12H3.75ZM12 3.75C16.5563 3.75 20.25 7.44365 20.25 12H21.75C21.75 6.61522 17.3848 2.25 12 2.25V3.75ZM14.25 10C14.25 11.2426 13.2426 12.25 12 12.25V13.75C14.0711 13.75 15.75 12.0711 15.75 10H14.25ZM12 12.25C10.7574 12.25 9.75 11.2426 9.75 10H8.25C8.25 12.0711 9.92893 13.75 12 13.75V12.25ZM9.75 10C9.75 8.75736 10.7574 7.75 12 7.75V6.25C9.92893 6.25 8.25 7.92893 8.25 10H9.75ZM12 7.75C13.2426 7.75 14.25 8.75736 14.25 10H15.75C15.75 7.92893 14.0711 6.25 12 6.25V7.75ZM6.88628 19.0648C7.08738 18.3955 7.49888 17.8088 8.05973 17.3919L7.16476 16.1881C6.34506 16.7975 5.74364 17.655 5.44972 18.6332L6.88628 19.0648ZM8.05973 17.3919C8.62058 16.9749 9.30091 16.7498 9.99978 16.75L10.0002 15.25C8.97879 15.2497 7.98447 15.5787 7.16476 16.1881L8.05973 17.3919ZM10 16.75H14V15.25H10V16.75ZM14.0003 16.75C14.7 16.7498 15.3812 16.9754 15.9424 17.3933L16.8383 16.1902C16.018 15.5794 15.0225 15.2496 13.9997 15.25L14.0003 16.75ZM15.9424 17.3933C16.5037 17.8113 16.9151 18.3992 17.1154 19.0697L18.5526 18.6403C18.2599 17.6604 17.6586 16.8011 16.8383 16.1902L15.9424 17.3933Z"
                fill={color === "default" ? "#081029" : color} />
        </svg>
    );
};

export default ProfileIcon;