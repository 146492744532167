import React, { useEffect, useRef } from "react";
import classnames from "classnames";
import AppButton from "../AppButton/AppButton";
import LinkIcon from "../../assets/icons/LinkIcon";
import ShareIcon from "../../assets/icons/ShareIcon";
import ReportIcon from "../../assets/icons/ReportIcon";
import AlertIcon from "../../assets/icons/AlertIcon";
import SaveIcon from "../../assets/icons/SaveIcon";
import EditIcon from "../../assets/icons/EditIcon";
import TrashIcon from "../../assets/icons/TrashIcon";
import PlusIcon from "../../assets/icons/PlusIcon";
import UploadIcon from "../../assets/icons/UploadIcon";
import DownloadIcon from "../../assets/icons/DownloadIcon";
import style from "./SandwichPopup.module.scss";

interface IProps {
    type: "new_product" | "product" | "report" | "edit_and_report" | "share_and_report" | "user" | "my_product" | "list";
    handleSandwichPopupClose: () => void;
    handleFirstButtonClick: () => void;
    handleSecondButtonClick?: () => void;
    handleThirdButtonClick?: () => void;
    handleFourthButtonClick?: () => void;
    isFollowed?: boolean;
    additionalClassNames?: string;
}

const SandwichPopup = ({
                           type,
                           handleSandwichPopupClose,
                           handleFirstButtonClick,
                           handleSecondButtonClick,
                           handleThirdButtonClick,
                           handleFourthButtonClick,
                           isFollowed,
                           additionalClassNames
                       }: IProps) => {
    const ref = useRef<HTMLDivElement>(null);

    const handleClick = (buttonNumber: 1 | 2 | 3 | 4) => {
        switch (buttonNumber) {
            case 1:
                handleFirstButtonClick();
                break;
            case 2:
                handleSecondButtonClick && handleSecondButtonClick();
                break;
            case 3:
                handleThirdButtonClick && handleThirdButtonClick();
                break;
            case 4:
                handleFourthButtonClick && handleFourthButtonClick();
                break;
        }
        handleSandwichPopupClose();
    };

    const handleClickOutside = (e: MouseEvent) => {
        if (ref.current && !ref.current.contains(e.target as HTMLElement)) {
            e.stopPropagation();
            handleSandwichPopupClose();
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, []);

    return (
        <div className={classnames(style.container, additionalClassNames)} ref={ref}>
            {type === "new_product" &&
                <>
                    <AppButton type="text" disabled={false} additionalClassNames={style.btn}
                               children={<><PlusIcon /><p>New Product</p></>}
                               onClick={() => handleClick(1)} />
                    <AppButton type="text" disabled={false} additionalClassNames={style.btn}
                               children={<><UploadIcon /><p>Upload List</p></>}
                               onClick={() => handleClick(2)} />
                </>
            }
            {type === "product" &&
                <>
                    <AppButton type="text" disabled={false} additionalClassNames={style.btn}
                               children={<><SaveIcon color="#292929" /><p>Save in List</p></>}
                               onClick={() => handleClick(1)} />
                    <AppButton type="text" disabled={false} additionalClassNames={style.btn}
                               children={<><ShareIcon color="#292929" /><p>Share</p></>}
                               onClick={() => handleClick(2)} />
                    <AppButton type="text" disabled={false} additionalClassNames={style.btn}
                               children={<><ReportIcon /><p>Report</p></>}
                               onClick={() => handleClick(3)} />
                </>
            }
            {type === "report" &&
                <AppButton type="text" disabled={false} additionalClassNames={style.btn}
                           children={<><ReportIcon /><p>Report</p></>} onClick={() => handleClick(1)} />
            }
            {type === "edit_and_report" &&
                <>
                    <AppButton type="text" disabled={false} additionalClassNames={style.btn}
                               children={<><ReportIcon /><p>Report</p></>}
                               onClick={() => handleClick(1)} />
                    <AppButton type="text" disabled={false} additionalClassNames={style.btn}
                               children={<><EditIcon /><p>Edit</p></>}
                               onClick={() => handleClick(2)} />
                </>
            }
            {type === "share_and_report" &&
                <>
                    <AppButton type="text" disabled={false} additionalClassNames={style.btn}
                               children={<><ShareIcon color="#292929" /><p>Share</p></>}
                               onClick={() => handleClick(1)} />
                    <AppButton type="text" disabled={false} additionalClassNames={style.btn}
                               children={<><ReportIcon /><p>Report</p></>}
                               onClick={() => handleClick(2)} />
                </>}
            {type === "user" &&
                <>
                    <AppButton type="text" disabled={false} additionalClassNames={style.btn}
                               children={<><LinkIcon /><p>{isFollowed ? "Unfollow" : "Follow"}</p></>}
                               onClick={() => handleClick(1)} />
                    <AppButton type="text" disabled={false} additionalClassNames={style.btn}
                               children={<><ShareIcon color="#292929" /><p>Share</p></>}
                               onClick={() => handleClick(2)} />
                    <AppButton type="text" disabled={false} additionalClassNames={style.btn}
                               children={<><ReportIcon /><p>Report</p></>}
                               onClick={() => handleClick(3)} />
                    <AppButton type="text" disabled={false} additionalClassNames={style.btn}
                               children={<><AlertIcon color="#292929" /><p>Block</p></>}
                               onClick={() => handleClick(4)} />
                </>}
            {type === "my_product" &&
                <>
                    <AppButton type="text" disabled={false} additionalClassNames={style.btn}
                               children={<><SaveIcon color="#292929" /><p>Save in List</p></>}
                               onClick={() => handleClick(1)} />
                    <AppButton type="text" disabled={false} additionalClassNames={style.btn}
                               children={<><EditIcon /><p>Edit</p></>}
                               onClick={() => handleClick(2)} />
                    <AppButton type="text" disabled={false} additionalClassNames={style.btn}
                               children={<><ShareIcon color="#292929" /><p>Share</p></>}
                               onClick={() => handleClick(3)} />
                    <AppButton type="text" disabled={false} additionalClassNames={style.btn}
                               children={<>
                                   <TrashIcon color="#E00000" /><p className={style.redText}>Remove Product</p>
                               </>}
                               onClick={() => handleClick(4)} />
                </>}
            {type === "list" &&
                <>
                    <AppButton type="text" disabled={false} additionalClassNames={style.btn}
                               children={<><DownloadIcon /><p>Download List</p></>}
                               onClick={() => handleClick(1)} />
                    <AppButton type="text" disabled={false} additionalClassNames={style.btn}
                               children={<><EditIcon /><p>Edit List</p></>}
                               onClick={() => handleClick(2)} />
                    <AppButton type="text" disabled={false} additionalClassNames={style.btn}
                               children={<>
                                   <TrashIcon color="#E00000" /><p className={style.redText}>Remove List</p>
                               </>}
                               onClick={() => handleClick(3)} />
                </>}
        </div>
    );
};

export default SandwichPopup;