import React, { useEffect, useState } from "react";
import { collection, DocumentReference, DocumentSnapshot, getDoc, getDocs, orderBy, query } from "firebase/firestore";
import { useAuth } from "../../hooks/hooks";
import Sidebar from "../Sidebar/Sidebar";
import NotificationList from "./NotificationList/NotificationList";
import Loader from "../../common/Loader/Loader";
import { db } from "../../utils/firebase_config";
import { INotification, IUser } from "../../utils/types";
import { emptyUser } from "../../utils/constants";
import style from "../NotificationsPage/NotificationsPage.module.scss";

interface IFetchNotificationData extends Omit<INotification, "sender"> {
    sender: DocumentReference;
}

const NotificationsPage = () => {
    const { user } = useAuth();
    const notificationsRef = collection(db, "users", user.id, "notifications");
    const notificationsQuery = query(notificationsRef, orderBy("createdAt", "desc"));
    const [notifications, setNotifications] = useState<INotification[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        fetchNotifications().then(data => setNotifications(data));
    }, []);

    const fetchNotifications = async () => {
        setIsLoading(true);
        let notificationsArray: INotification[] = [];
        const querySnapshot = await getDocs(notificationsQuery);
        const senderPromises: Promise<DocumentSnapshot<IUser>>[] = [];

        querySnapshot.forEach(doc => {
            const { id } = doc;
            const { sender: senderRef, type, createdAt, unread } = doc.data() as IFetchNotificationData;
            notificationsArray.push({ id, type, createdAt, sender: emptyUser, unread });
            senderPromises.push(getDoc(senderRef) as Promise<DocumentSnapshot<IUser>>);
        });

        await Promise
            .all(senderPromises)
            .then(values => {
                    notificationsArray = notificationsArray.map((notification, index) => {
                        const { id } = values[index];
                        const { displayName, email, avatarUrl } = values[index].data() || emptyUser;
                        return ({
                            ...notification,
                            sender: { id, displayName, email, avatarUrl }
                        });
                    });
                }
            );

        setIsLoading(false);

        return notificationsArray;
    };

    const handleNotificationRead = (id: string) => {
        setNotifications(prevState => prevState.map(notification => notification.id === id ? {
            ...notification,
            unread: false
        } : notification));
    };

    return (
        <div className={style.container}>
            <Sidebar additionalClassNames={style.stickySidebar} />
            {isLoading ? <Loader className={style.loader} /> :
                <NotificationList notifications={notifications} handleNotificationRead={handleNotificationRead} />}
        </div>
    );
};

export default NotificationsPage;