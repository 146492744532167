import React from "react";
import classnames from "classnames";
import { useAuth } from "../../hooks/hooks";
import Sidebar from "../Sidebar/Sidebar";
import List from "./List/List";
import style from "./HomePage.module.scss";

const HomePage = () => {
    const { isAuthorized, user } = useAuth();

    return (
        <div className={classnames(style.container, !isAuthorized && style.unauthorizedContainer)}>
            <Sidebar additionalClassNames={style.stickySidebar} />
            <List isAuthorized={isAuthorized} user={user} />
        </div>
    );
};

export default HomePage;