import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteDoc, doc } from "firebase/firestore";
import moment from "moment";
import { useAuth } from "../../../../../hooks/hooks";
import Avatar from "../../../../../common/Avatar/Avatar";
import AppButton from "../../../../../common/AppButton/AppButton";
import DotsIcon from "../../../../../assets/icons/DotsIcon";
import StarIcon from "../../../../../assets/icons/StarIcon";
import ModalWrapper from "../../../../../common/ModalWrapper/ModalWrapper";
import SandwichPopup from "../../../../../common/SandwichPopup/SandwichPopup";
import SelectLists from "../../../../../common/SelectLists/SelectLists";
import RemovePopup from "../../../../../common/RemovePopup/RemovePopup";
import ReportPopup from "../../../../../common/ReportPopup/ReportPopup";
import AddNewOrEditProductPopup from "../../../../../common/AddNewOrEditProductPopup/AddNewOrEditProductPopup";
import { db } from "../../../../../utils/firebase_config";
import { nFormatter } from "../../../../../utils/functions";
import { IList, IProductWithDescriptionAndUrl } from "../../../../../utils/types";
import style from "./Product.module.scss";

interface IProps {
    product: IProductWithDescriptionAndUrl;
    isMyProfile: boolean;
    handleProductRemove: (id: string) => void;
    handleListAdd: (newList: IList) => void;
    handleUpdateProductAfterEdit: (id: string, newTitle: string, newDescription: string, newUrl: string, newImageUrl: string) => void;
}

const Product = ({
                     product,
                     isMyProfile,
                     handleProductRemove,
                     handleListAdd,
                     handleUpdateProductAfterEdit
                 }: IProps) => {
    const navigate = useNavigate();
    const { isAuthorized } = useAuth();
    const productRef = doc(db, "products", product.id);
    const [isSandwichPopupOpen, setIsSandwichPopupOpen] = useState<boolean>(false);
    const [isAddToListsPopupOpen, setIsAddToListsPopupOpen] = useState<boolean>(false);
    const [isRemoveProductPopupOpen, setIsRemoveProductPopupOpen] = useState<boolean>(false);
    const [isRemoveLoading, setIsRemoveLoading] = useState<boolean>(false);
    const [isReportPopupOpen, setIsReportPopupOpen] = useState<boolean>(false);
    const [isEditProductPopupOpen, setIsEditProductPopupOpen] = useState<boolean>(false);

    const handleContainerClick = () => {
        navigate(`/product_details/${product.id}`, { state: { from: "user profile products" } });
    };

    const handleDotsClick = () => {
        setIsSandwichPopupOpen(true);
    };

    const handleRemoveClick = async () => {
        setIsRemoveLoading(true);
        await deleteDoc(productRef);
        setIsRemoveLoading(false);
        setIsRemoveProductPopupOpen(false);
        handleProductRemove(product.id);
    };

    return (
        <>
            <div className={style.container} onClick={handleContainerClick}>
                <div className={style.header}>
                    <Avatar src={product.influencer?.avatarUrl || ""} />
                    <div className={style.text}>
                        <h2>{product.influencer?.displayName}</h2>
                        <h3>{moment(product.createdAt.toDate()).calendar()}</h3>
                    </div>
                    <AppButton type="text" disabled={!isAuthorized} additionalClassNames={style.iconBtn}
                               children={<DotsIcon color="#A3A3A3" className={style.icon} />}
                               onClick={handleDotsClick}
                    />
                    {isSandwichPopupOpen && <SandwichPopup type={isMyProfile ? "my_product" : "product"}
                                                           handleSandwichPopupClose={() => setIsSandwichPopupOpen(false)}
                                                           handleFirstButtonClick={() => setIsAddToListsPopupOpen(true)}
                                                           handleSecondButtonClick={() => setIsEditProductPopupOpen(true)}
                                                           handleThirdButtonClick={isMyProfile ? undefined : () => setIsReportPopupOpen(true)}
                                                           handleFourthButtonClick={() => setIsRemoveProductPopupOpen(true)}
                    />}
                </div>
                <img src={product.imageUrl} alt="Product" className={style.productImg} />
                <h2 className={style.name}>{product.title}</h2>
                <div className={style.reviews}>
                    <StarIcon />
                    <p>({product.averageRating.toFixed(1)}) - {nFormatter(product.reviewsCount, 1)} Reviews</p>
                </div>
            </div>
            {isAddToListsPopupOpen &&
                <ModalWrapper onClick={() => setIsAddToListsPopupOpen(false)}>
                    <SelectLists productId={product.id} setIsAddToListsPopupOpen={setIsAddToListsPopupOpen}
                                 handleListAdd={handleListAdd}
                                 isFromAddOrEditProductPopup={false} />
                </ModalWrapper>
            }
            {isRemoveProductPopupOpen &&
                <ModalWrapper onClick={() => setIsRemoveProductPopupOpen(isRemoveLoading)}>
                    <RemovePopup type="product"
                                 onCancel={() => setIsRemoveProductPopupOpen(false)}
                                 onConfirm={handleRemoveClick}
                                 isLoading={isRemoveLoading}
                    />
                </ModalWrapper>
            }
            {isReportPopupOpen &&
                <ModalWrapper onClick={() => setIsReportPopupOpen(false)}>
                    <ReportPopup setIsReportPopupOpen={setIsReportPopupOpen} reportType="product"
                                 productId={product.id} />
                </ModalWrapper>}
            {isEditProductPopupOpen && product &&
                <ModalWrapper onClick={() => setIsEditProductPopupOpen(false)}>
                    <AddNewOrEditProductPopup
                        setIsModalOpen={setIsEditProductPopupOpen}
                        isEdit={true}
                        productFormData={{
                            id: product.id,
                            title: product.title,
                            description: product.description,
                            url: product.url,
                            lists: [],
                            file: null
                        }}
                        imageOfExistingProduct={product.imageUrl}
                        onProductEdit={handleUpdateProductAfterEdit}
                    />
                </ModalWrapper>
            }
        </>
    );
};

export default Product;