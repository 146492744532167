import React from "react";

interface IProps {
    color?: string;
    className?: string;
}

const LogoutIcon = ({ color = "default", className = "" }: IProps) => {
    return (
        <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.25 8C13.25 8.41421 13.5858 8.75 14 8.75C14.4142 8.75 14.75 8.41421 14.75 8H13.25ZM12 4V3.25V4ZM5 4V3.25V4ZM3 6H2.25H3ZM3 18H2.25H3ZM14.75 16C14.75 15.5858 14.4142 15.25 14 15.25C13.5858 15.25 13.25 15.5858 13.25 16H14.75ZM7 11.25C6.58579 11.25 6.25 11.5858 6.25 12C6.25 12.4142 6.58579 12.75 7 12.75V11.25ZM21 12L21.5303 12.5303C21.8232 12.2374 21.8232 11.7626 21.5303 11.4697L21 12ZM18.5303 8.46967C18.2374 8.17678 17.7626 8.17678 17.4697 8.46967C17.1768 8.76256 17.1768 9.23744 17.4697 9.53033L18.5303 8.46967ZM17.4697 14.4697C17.1768 14.7626 17.1768 15.2374 17.4697 15.5303C17.7626 15.8232 18.2374 15.8232 18.5303 15.5303L17.4697 14.4697ZM14.75 8V6H13.25V8H14.75ZM14.75 6C14.75 5.27065 14.4603 4.57118 13.9445 4.05546L12.8839 5.11612C13.1183 5.35054 13.25 5.66848 13.25 6H14.75ZM13.9445 4.05546C13.4288 3.53973 12.7293 3.25 12 3.25V4.75C12.3315 4.75 12.6495 4.8817 12.8839 5.11612L13.9445 4.05546ZM12 3.25H5V4.75H12V3.25ZM5 3.25C4.27065 3.25 3.57118 3.53973 3.05546 4.05546L4.11612 5.11612C4.35054 4.8817 4.66848 4.75 5 4.75V3.25ZM3.05546 4.05546C2.53973 4.57118 2.25 5.27065 2.25 6H3.75C3.75 5.66848 3.8817 5.35054 4.11612 5.11612L3.05546 4.05546ZM2.25 6V18H3.75V6H2.25ZM2.25 18C2.25 18.7293 2.53973 19.4288 3.05546 19.9445L4.11612 18.8839C3.8817 18.6495 3.75 18.3315 3.75 18H2.25ZM3.05546 19.9445C3.57118 20.4603 4.27065 20.75 5 20.75V19.25C4.66848 19.25 4.35054 19.1183 4.11612 18.8839L3.05546 19.9445ZM5 20.75H12V19.25H5V20.75ZM12 20.75C12.7293 20.75 13.4288 20.4603 13.9445 19.9445L12.8839 18.8839C12.6495 19.1183 12.3315 19.25 12 19.25V20.75ZM13.9445 19.9445C14.4603 19.4288 14.75 18.7293 14.75 18H13.25C13.25 18.3315 13.1183 18.6495 12.8839 18.8839L13.9445 19.9445ZM14.75 18V16H13.25V18H14.75ZM7 12.75H21V11.25H7V12.75ZM21.5303 11.4697L18.5303 8.46967L17.4697 9.53033L20.4697 12.5303L21.5303 11.4697ZM18.5303 15.5303L21.5303 12.5303L20.4697 11.4697L17.4697 14.4697L18.5303 15.5303Z"
                fill={color === "default" ? "#081029" : color} />
        </svg>
    );
};

export default LogoutIcon;