import React, { useEffect, useState } from "react";
import { collection, DocumentReference, getDocs, query, where } from "firebase/firestore";
import Search from "./Search/Search";
import Product from "./Product/Product";
import Loader from "../../../../common/Loader/Loader";
import { db } from "../../../../utils/firebase_config";
import { IInfluencerWithBio, IList, IProductWithDescriptionAndUrl, SetStateType } from "../../../../utils/types";
import style from "./Products.module.scss";

interface IProps {
    isMyProfile: boolean;
    userProfileData: IInfluencerWithBio | null;
    userRef: DocumentReference | null;
    userProducts: IProductWithDescriptionAndUrl[],
    setUserProducts: SetStateType<IProductWithDescriptionAndUrl[]>;
    handleProductRemove: (id: string) => void;
    handleListAdd: (newList: IList) => void;
}

const Products = ({
                      isMyProfile,
                      userProfileData,
                      userRef,
                      userProducts,
                      setUserProducts,
                      handleProductRemove,
                      handleListAdd
                  }: IProps) => {
    const productsRef = collection(db, "products");
    const userProductsQuery = userRef ? query(productsRef, where("influencer", "==", userRef)) : null;
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (userProfileData && userProfileData.id) {
            fetchUserProducts().then(data => {
                setUserProducts(data);
            });
        }
    }, [userProfileData?.id]);

    const fetchUserProducts = async () => {
        setIsLoading(true);

        let productsArray: IProductWithDescriptionAndUrl[] = [];

        if (userProductsQuery) {
            const querySnapshot = await getDocs(userProductsQuery);

            if (userProfileData) {

                const {
                    id: userId,
                    displayName,
                    email,
                    avatarUrl,
                    followersCount,
                    followingsCount,
                    productsCount,
                    publicListsCount,
                    isFollowedByCurrentUser
                } = userProfileData;

                querySnapshot.forEach(doc => {
                    const { id: productId } = doc;
                    const { title, description, url, imageUrl, createdAt, reviewsCount, averageRating } = doc.data();

                    productsArray.push({
                        id: productId,
                        title,
                        description,
                        url,
                        imageUrl,
                        createdAt,
                        reviewsCount,
                        averageRating,
                        influencer: {
                            id: userId,
                            displayName,
                            email,
                            avatarUrl,
                            followersCount,
                            followingsCount,
                            productsCount,
                            publicListsCount,
                            isFollowedByCurrentUser
                        }
                    });
                });
            }
        }

        setIsLoading(false);

        return productsArray;
    };

    const handleUpdateProductAfterEdit = (id: string, title: string, description: string, url: string, imageUrl: string) => {
        setUserProducts(prevState => prevState.map(el => el.id === id ? {
            ...el,
            title,
            description,
            url,
            imageUrl
        } : el));
    };

    return (
        <div className={style.container}>
            <Search />
            {isLoading ? <Loader className={style.loader} /> :
                userProducts.map(item => <Product key={item.id} product={item} isMyProfile={isMyProfile}
                                                  handleProductRemove={handleProductRemove}
                                                  handleListAdd={handleListAdd}
                                                  handleUpdateProductAfterEdit={handleUpdateProductAfterEdit} />)}
        </div>
    );
};

export default Products;