import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { doc, onSnapshot, Unsubscribe } from "firebase/firestore";
import { setUnreadNotificationsCount } from "./store/mainReducer/mainSlice";
import { auth, db } from "./utils/firebase_config";
import { login } from "./store/authReducer/authSlice";
import { useAppDispatch, useAuth } from "./hooks/hooks";

import AuthRoute from "./components/AuthRoute/AuthRoute";
import Header from "./components/Header/Header";
import HomePage from "./components/HomePage/HomePage";
import ProductPage from "./components/ProductPage/ProductPage";
import NotificationsPage from "./components/NotificationsPage/NotificationsPage";
import UserProfilePage from "./components/UserProfilePage/UserProfilePage";

const App = () => {
    const dispatch = useAppDispatch();
    const { user, isAuthorized } = useAuth();
    const userRef = isAuthorized ? doc(db, "users", user.id) : null;

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, result => {
            if (result) {
                const { uid: id, displayName, email, photoURL: avatarUrl } = result;
                dispatch(login({ id, displayName, email, avatarUrl }));
            }
        });
        return () => {
            unsubscribe();
        };
    }, []);

    useEffect(() => {
        let unsubscribe: Unsubscribe | null = null;
        if (userRef) {
            unsubscribe = onSnapshot(userRef, doc => {
                const data = doc.data() as { unreadNotificationsCount: number } || undefined;
                if (data) {
                    const { unreadNotificationsCount } = data;
                    dispatch(setUnreadNotificationsCount(unreadNotificationsCount));
                }
            });
        }
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [userRef]);

    return (
        <>
            <Header />
            <Routes>
                <Route path="/" element={<Navigate to="/home" />} />
                <Route path="/home" element={<HomePage />} />
                <Route path="/product_details/:id" element={<ProductPage />} />
                <Route path="/user_profile/:id" element={<UserProfilePage isMyProfile={false} />} />
                <Route path="/notifications"
                       element={
                           <AuthRoute>
                               <NotificationsPage />
                           </AuthRoute>}
                />
                <Route path="/my_profile"
                       element={
                           <AuthRoute>
                               <UserProfilePage isMyProfile={true} />
                           </AuthRoute>}
                />
            </Routes>
        </>
    );
};

export default App;