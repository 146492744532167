import React from "react";

const ReportIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5 20.4293V4.42929C5.93464 3.51315 7.19124 3 8.5 3C9.80876 3 11.0654 3.51315 12 4.42929C12.9346 5.34542 14.1912 5.85857 15.5 5.85857C16.8088 5.85857 18.0654 5.34542 19 4.42929V13.4293C18.0654 14.3454 16.8088 14.8586 15.5 14.8586C14.1912 14.8586 12.9346 14.3454 12 13.4293C11.0654 12.5132 9.80876 12 8.5 12C7.19124 12 5.93464 12.5132 5 13.4293"
                stroke="#292929" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default ReportIcon;