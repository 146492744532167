import React from "react";

interface IProps {
    color?: string;
}

const DropdownCaretIcon = ({ color = "default" }: IProps) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 9L12 15L18 9" stroke={color === "default" ? "#081029" : color} strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round" />
        </svg>
    );
};

export default DropdownCaretIcon;