import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "../../utils/types";
import { emptyUser } from "../../utils/constants";

interface IAuthState {
    user: IUser;
}

const initialState: IAuthState = {
    user: emptyUser
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        login(state, action: PayloadAction<IUser>) {
            state.user = action.payload;
        },
        logout(state) {
            state.user = emptyUser;
        }
    }
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;