import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { signOut } from "firebase/auth";

import { auth } from "../../utils/firebase_config";
import { logout } from "../../store/authReducer/authSlice";
import { setUnreadNotificationsCount } from "../../store/mainReducer/mainSlice";
import { useAppDispatch, useAppSelector, useAuth } from "../../hooks/hooks";

import Avatar from "../../common/Avatar/Avatar";
import AppButton from "../../common/AppButton/AppButton";
import HomeIcon from "../../assets/icons/HomeIcon";
import ListIcon from "../../assets/icons/ListIcon";
import ProfileIcon from "../../assets/icons/ProfileIcon";
import NotificationsIcon from "../../assets/icons/NotificationsIcon";
import LogoutIcon from "../../assets/icons/LogoutIcon";
import { SetStateType } from "../../utils/types";
import style from "./Sidebar.module.scss";

interface IProps {
    additionalClassNames?: string;
    setIsMobileMenuOpen?: SetStateType<boolean>;
}

const Sidebar = ({ additionalClassNames, setIsMobileMenuOpen }: IProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { user, isAuthorized } = useAuth();
    const { unreadNotificationsCount } = useAppSelector(state => state.mainReducer);

    const handleNavButtonClick = (to: string, state?: { isLists: boolean }) => {
        navigate(to, { state });
        setIsMobileMenuOpen && setIsMobileMenuOpen(false);
    };

    const handleLogoutClick = () => {
        signOut(auth).then(() => {
            dispatch(logout());
            dispatch(setUnreadNotificationsCount(0));
            setIsMobileMenuOpen && setIsMobileMenuOpen(false);
        }).catch((error) => {
            console.log(error);
        });
    };

    return isAuthorized ? (
        <nav className={classNames(style.container, additionalClassNames)}>
            <div className={style.user}>
                <Avatar src={user.avatarUrl || ""} />
                <div className={style.text}>
                    <h2>{user.displayName}</h2>
                    <h3>@{user.email}</h3>
                </div>
            </div>
            <div className={style.links}>
                <AppButton
                    children={<>
                        <HomeIcon color={location.pathname === "/home" ? "#2C56E4" : "#525252"}
                                  className={style.icon} />
                        <p className={classNames(location.pathname === "/home" && style.active)}>Home</p>
                    </>}
                    type="text" disabled={false}
                    additionalClassNames={style.link}
                    onClick={() => handleNavButtonClick("/home")}
                />
                <AppButton
                    children={<>
                        <ListIcon
                            color={location.pathname === "/my_profile" && location?.state?.isLists ? "#2C56E4" : "#525252"}
                            className={style.icon} />
                        <p className={classNames(location.pathname === "/my_profile" && location?.state?.isLists && style.active)}>Lists</p>
                    </>}
                    type="text" disabled={false}
                    additionalClassNames={style.link}
                    onClick={() => handleNavButtonClick("/my_profile", { isLists: true })}
                />
                <AppButton
                    children={<>
                        <ProfileIcon
                            color={location.pathname === "/my_profile" && !location?.state?.isLists ? "#2C56E4" : "#525252"}
                            className={style.icon} />
                        <p className={classNames(location.pathname === "/my_profile" && !location?.state?.isLists && style.active)}>Profile</p>
                    </>}
                    type="text" disabled={false}
                    additionalClassNames={style.link}
                    onClick={() => handleNavButtonClick("/my_profile", { isLists: false })}
                />
                <AppButton
                    children={<>
                        <div className={style.notificationIconAndCounterWrapper}>
                            <NotificationsIcon color={location.pathname === "/notifications" ? "#2C56E4" : "#525252"}
                                               className={style.icon} />
                            {unreadNotificationsCount > 0 &&
                                <p className={style.notificationCounter}>
                                    {unreadNotificationsCount < 9 ? unreadNotificationsCount : "9+"}
                                </p>}
                        </div>
                        <p className={classNames(location.pathname === "/notifications" && style.active)}>Notifications</p>
                    </>}
                    type="text" disabled={false}
                    additionalClassNames={style.link}
                    onClick={() => handleNavButtonClick("/notifications")}
                />
            </div>
            <AppButton
                children={<>
                    <LogoutIcon color="#E00000" className={style.icon} />
                    <p>Log Out</p>
                </>}
                type="text" disabled={false}
                additionalClassNames={style.logout}
                onClick={handleLogoutClick}
            />
        </nav>
    ) : null;
};

export default Sidebar;