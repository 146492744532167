import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import classnames from "classnames";
import { setSearchQuery } from "../../store/mainReducer/mainSlice";
import { useAppDispatch, useAppSelector, useAuth } from "../../hooks/hooks";
import AuthorizedContainer from "./AuthorizedContainer/AuthorizedContainer";
import GoogleAuthButton from "./GoogleAuthButton/GoogleAuthButton";
import Sidebar from "../Sidebar/Sidebar";
import SearchIcon from "../../assets/icons/SearchIcon";
import AppButton from "../../common/AppButton/AppButton";
import CloseIcon from "../../assets/icons/CloseIcon";
import ArrowIcon from "../../assets/icons/ArrowIcon";
import style from "./Header.module.scss";

const urlParams = new URLSearchParams(window.location.search);
const q = urlParams.get('q');

const Header = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { user, isAuthorized } = useAuth();
    // const { searchQuery } = useAppSelector(state => state.mainReducer);

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
    const [queryField, setQueryField] = useState<string>(q ? "" + q : "");
    const [isMobileSearchOpen, setIsMobileSearchOpen] = useState<boolean>(false);
    const [isQueryOnLoadExecuted, setIsQueryOnLoadExecuted] = useState<boolean>(false);

    const handleLogoWrapperClick = () => {
        setQueryField("")
        dispatch(setSearchQuery(""));
        navigate("/home");
    };

    const handleBackButtonClick = () => {
        navigate(-1);
    };

    const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        //dispatch(setSearchQuery(e.target.value));
        setQueryField(e.target.value)
    };
    const handleEnterSearchInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            dispatch(setSearchQuery(queryField));
        }

    };

    if (!isQueryOnLoadExecuted && q) {
        dispatch(setSearchQuery(queryField))
        setIsQueryOnLoadExecuted(true);
    }
    return (
        <header className={style.header}>
            <div className={style.container}>
                <div className={style.logoWrapper}>
                    <div className={style.logo} onClick={handleLogoWrapperClick} />
                    <p onClick={handleLogoWrapperClick}>Home</p>
                    <AppButton type="text" disabled={false} additionalClassNames={style.backBtn}
                        onClick={handleBackButtonClick}
                        children={<><ArrowIcon /></>} />
                </div>
                <AppButton additionalClassNames={style.mobileSearchButton} children={<SearchIcon color="#A3A3A3" />}
                    type={"contained"} disabled={false}
                    onClick={() => setIsMobileSearchOpen(prevState => !prevState)} />
                <div
                    className={classnames(style.search, isMobileSearchOpen && style.openedMobileSearch)}>
                    <SearchIcon color="#A3A3A3" />
                    <input type="search" placeholder="Search Product Or Person"
                        spellCheck="false"
                        value={queryField}
                        onChange={handleSearchInputChange}
                        onKeyDown={handleEnterSearchInput} />
                </div>
                {isAuthorized ?
                    <AuthorizedContainer avatarUrl={user.avatarUrl} setIsMobileMenuOpen={setIsMobileMenuOpen} /> :
                    <GoogleAuthButton />}
                {isAuthorized && <div
                    className={classnames(style.mobileSidebarWrapper, isMobileMenuOpen && style.openedMobileSidebarWrapper)}>
                    <AppButton type="text" disabled={false} additionalClassNames={style.closeBtn}
                        onClick={() => setIsMobileMenuOpen(false)}
                        children={<CloseIcon className={style.closeBtnIcon} />} />
                    <Sidebar
                        additionalClassNames={style.mobileSidebar} setIsMobileMenuOpen={setIsMobileMenuOpen} />
                </div>}
            </div>
        </header>
    );
};

export default Header;