import React, { useState } from "react";
import {
    addDoc,
    deleteDoc,
    CollectionReference,
    DocumentReference
} from "firebase/firestore";
import classnames from "classnames";
import { useAuth, useSignInWithPopup } from "../../../hooks/hooks";
import Avatar from "../../../common/Avatar/Avatar";
import AppButton from "../../../common/AppButton/AppButton";
import FollowIcon from "../../../assets/icons/FollowIcon";
import DotsIcon from "../../../assets/icons/DotsIcon";
import SandwichPopup from "../../../common/SandwichPopup/SandwichPopup";
import Loader from "../../../common/Loader/Loader";
import ModalWrapper from "../../../common/ModalWrapper/ModalWrapper";
import ReportPopup from "../../../common/ReportPopup/ReportPopup";
import { nFormatter } from "../../../utils/functions";
import { IInfluencerWithBio, SetStateType } from "../../../utils/types";
import style from "./Leftbar.module.scss";

interface IProps {
    isMyProfile: boolean;
    userProfileData: IInfluencerWithBio | null;
    followingDoc: DocumentReference | null;
    followingsRef: CollectionReference;
    currentUserRef: DocumentReference | null;
    userRef: DocumentReference | null;
    handleFollowAndUnfollowUser: () => void;
    setFollowingDoc: SetStateType<DocumentReference | null>;
    isLoading: boolean;
}

const Leftbar = ({
                     isMyProfile,
                     userProfileData,
                     followingDoc,
                     followingsRef,
                     currentUserRef,
                     userRef,
                     handleFollowAndUnfollowUser,
                     setFollowingDoc,
                     isLoading
                 }: IProps) => {
    const { isAuthorized } = useAuth();
    const signInWithPopup = useSignInWithPopup();
    const [isMoreOpen, setIsMoreOpen] = useState<boolean>(false);
    const [isFollowUnfollowLoading, setIsFollowUnfollowLoading] = useState<boolean>(false);
    const [isSandwichPopupOpen, setIsSandwichPopupOpen] = useState<boolean>(false);
    const [isReportPopupOpen, setIsReportPopupOpen] = useState<boolean>(false);

    const handleFollowAndUnfollowClick = async () => {
        setIsFollowUnfollowLoading(true);
        if (userProfileData) {
            if (userProfileData.isFollowedByCurrentUser && followingDoc) {
                await deleteDoc(followingDoc);
            } else {
                const newFollowingDocRef = await addDoc(followingsRef, {
                    user: currentUserRef,
                    followedUser: userRef
                });
                setFollowingDoc(newFollowingDocRef);
            }
        }
        handleFollowAndUnfollowUser();
        setIsFollowUnfollowLoading(false);
    };

    return (
        <>
            {isLoading ? <Loader className={style.largeLoader} /> : <aside className={style.container}>
                {!isMyProfile && <AppButton type="text" disabled={!isAuthorized} additionalClassNames={style.dotsBtn}
                                            children={<DotsIcon color="#A3A3A3" className={style.icon} />}
                                            onClick={() => setIsSandwichPopupOpen(true)}
                />}
                {isSandwichPopupOpen && <SandwichPopup type="user"
                                                       handleSandwichPopupClose={() => setIsSandwichPopupOpen(false)}
                                                       handleFirstButtonClick={handleFollowAndUnfollowClick}
                                                       handleThirdButtonClick={() => setIsReportPopupOpen(true)}
                                                       isFollowed={userProfileData?.isFollowedByCurrentUser}
                                                       additionalClassNames={style.sandwichPopup} />}
                <Avatar src={userProfileData?.avatarUrl || ""} className={style.avatar} />
                <h2>{userProfileData?.displayName}</h2>
                <h3>{userProfileData?.email}</h3>
                {!isMyProfile && <>
                    <AppButton type={userProfileData?.isFollowedByCurrentUser ? "outlined" : "contained"}
                               disabled={isFollowUnfollowLoading}
                               additionalClassNames={style.followButton}
                               children={isFollowUnfollowLoading ? <Loader className={style.smallLoader} /> :
                                   <>
                                       <FollowIcon
                                           color={userProfileData?.isFollowedByCurrentUser ? "#2C56E4" : "#FFFFFF"}
                                           className={style.followIcon} />
                                       <p>{userProfileData?.isFollowedByCurrentUser ? "Unfollow" : "Follow"}</p>
                                   </>
                               }
                               onClick={isAuthorized ? handleFollowAndUnfollowClick : signInWithPopup}
                    />
                    {/*//TODO uncomment after the recommended By will be implemented*/}
                    {/*<div className={classnames(style.followedBy, isMoreOpen && style.openedFollowedBy)}>*/}
                    {/*    <Avatar src="https://www.w3schools.com/howto/img_avatar.png" className={style.avatar} />*/}
                    {/*    <p>followed by <span>Antonio Band,</span></p>*/}
                    {/*</div>*/}
                </>}
                <div
                    className={classnames(style.followingAndFollowers, isMoreOpen && style.openedFollowingAndFollowers)}>
                    <div>
                        <h2>Following</h2>
                        <h3>{nFormatter(userProfileData?.followingsCount || 0, 1)}</h3>
                    </div>
                    <div>
                        <h2>Followers</h2>
                        <h3>{nFormatter(userProfileData?.followersCount || 0, 1)}</h3>
                    </div>
                </div>
                <div className={classnames(style.bio, isMoreOpen && style.openedBio)}>
                    <h2>Bio</h2>
                    <p>{userProfileData?.bio}</p>
                </div>
                <AppButton type="text" disabled={false} additionalClassNames={style.showMore}
                           children={<>{isMoreOpen ? "Hide more" : "Show more"}</>}
                           onClick={() => setIsMoreOpen(prev => !prev)} />
            </aside>}
            {isReportPopupOpen &&
                <ModalWrapper onClick={() => setIsReportPopupOpen(false)}>
                    <ReportPopup setIsReportPopupOpen={setIsReportPopupOpen} reportType="user"
                                 reportedUserId={userProfileData?.id} />
                </ModalWrapper>}
        </>
    );
};

export default Leftbar;