import React, { useEffect, useState } from "react";
import { arrayUnion, collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import classnames from "classnames";
import { useAuth } from "../../hooks/hooks";
import AppButton from "../AppButton/AppButton";
import ListOfLists from "../ListOfLists/ListOfLists";
import Loader from "../Loader/Loader";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import AddNewOrEditListPopup from "../AddNewOrEditListPopup/AddNewOrEditListPopup";
import { db } from "../../utils/firebase_config";
import { IList, IListReduced } from "../../utils/types";
import style from "./SelectLists.module.scss";

interface IProps {
    productId?: string;
    setIsAddToListsPopupOpen: (value: boolean) => void;
    handleListAdd?: (newList: IList) => void;
    additionalClassnames?: string;
    isFromAddOrEditProductPopup: boolean;
    onConfirmIfIsFromAddOrEditProductPopup?: (lists: IListReduced[]) => void;
    initialSelectedLists?: IListReduced[];
}

const SelectLists = ({
                         productId,
                         setIsAddToListsPopupOpen,
                         handleListAdd,
                         additionalClassnames,
                         isFromAddOrEditProductPopup,
                         onConfirmIfIsFromAddOrEditProductPopup,
                         initialSelectedLists
                     }: IProps) => {
    const { user } = useAuth();
    const userRef = doc(db, "users", user.id);
    const productRef = productId ? doc(db, "products", productId) : null;
    const listsRef = collection(db, "lists");
    const userListsQuery = query(listsRef, where("author", "==", userRef), where("accessStatus", "in", ["public", "private"]));
    const [isListsLoading, setIsListsLoading] = useState<boolean>(false);
    const [isAddListToLoading, setIsAddListToLoading] = useState<boolean>(false);
    const [userLists, setUserLists] = useState<IListReduced[]>([]);
    const [selectedLists, setSelectedLists] = useState<IListReduced[]>(initialSelectedLists || []);
    const [isAddNewListPopupOpen, setIsAddNewListPopupOpen] = useState<boolean>(false);

    useEffect(() => {
        fetchUserLists().then(data => setUserLists(data));
    }, []);

    const fetchUserLists = async () => {
        setIsListsLoading(true);
        const listsArray: IListReduced[] = [];
        const querySnapshot = await getDocs(userListsQuery);

        querySnapshot.forEach(doc => {
            const { id } = doc;
            const { name, accessStatus } = doc.data();
            listsArray.push({ id, name, accessStatus });
        });

        setIsListsLoading(false);

        return listsArray;
    };

    const handleContainerClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
    };

    const handleListsChange = (isAdd: boolean, currentList: IListReduced) => {
        setSelectedLists(isAdd ? [...selectedLists, currentList] : selectedLists.filter(list => list.id !== currentList.id));
    };

    const handleDoneButtonClick = async () => {
        if (isFromAddOrEditProductPopup) {
            onConfirmIfIsFromAddOrEditProductPopup && onConfirmIfIsFromAddOrEditProductPopup(selectedLists);
            setIsAddToListsPopupOpen(false);
        } else if (productRef) {
            setIsAddListToLoading(true);

            const promises: Promise<void>[] = [];

            selectedLists.forEach(list => {
                const listRef = doc(db, "lists", list.id);
                promises.push(updateDoc(listRef, {
                        productsReferences: arrayUnion(productRef)
                    })
                );
            });

            await Promise.all(promises);

            setIsAddListToLoading(false);
            setIsAddToListsPopupOpen(false);
        }
    };

    return (
        <>
            <div className={classnames(style.container, additionalClassnames)} onClick={handleContainerClick}>
                <div className={style.header}>
                    <h2>Lists</h2>
                    <AppButton type="outlined" disabled={false} additionalClassNames={style.createBtn}
                               children={<>+ Create New List</>}
                               onClick={() => setIsAddNewListPopupOpen(true)}
                    />
                </div>
                {isListsLoading ? <Loader /> :
                    <ListOfLists
                        additionalClassnames={""}
                        items={userLists}
                        selectedItems={selectedLists}
                        handleListsChange={handleListsChange}
                    />}
                <AppButton type="contained"
                           disabled={isListsLoading || isAddListToLoading || (!isFromAddOrEditProductPopup && selectedLists.length === 0)}
                           additionalClassNames={""}
                           children={isAddListToLoading ? <Loader className={style.loader} /> : <>Done</>}
                           onClick={handleDoneButtonClick}
                />
            </div>
            {isAddNewListPopupOpen &&
                <ModalWrapper onClick={() => setIsAddNewListPopupOpen(false)}>
                    <AddNewOrEditListPopup
                        userRef={userRef}
                        listsRef={listsRef}
                        setIsAddNewOrEditListPopupOpen={setIsAddNewListPopupOpen}
                        setUserLists={setUserLists}
                        handleListAdd={handleListAdd}
                        isEdit={false} />
                </ModalWrapper>}
        </>
    );
};

export default SelectLists;