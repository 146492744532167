export const nFormatter = (num: number, digits: number) => {
    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "K" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    const item = lookup
        .slice()
        .reverse()
        .find(function(item) {
            return num >= item.value;
        });

    const getFlooredFixed = (x: number) => {
        return (Math.floor(x * Math.pow(10, digits)) / Math.pow(10, digits)).toFixed(digits);
    };

    return item ? getFlooredFixed(num / item.value).replace(rx, "$1") + item.symbol : "0";
};

export const commasFormatter = (num: number) => {
    return new Intl.NumberFormat().format(num);
};