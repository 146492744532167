import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import classnames from "classnames";
import StarIcon from "../../../assets/icons/StarIcon";
import AppButton from "../../../common/AppButton/AppButton";
import Loader from "../../../common/Loader/Loader";
import style from "./PostOrEditReviewPopup.module.scss";

interface IProps {
    isEdit: boolean;
    handleAddReview?: (rating: number, text: string) => Promise<void>;
    handleEditReview?: (newRating: number, newText: string) => Promise<void>;
    prevText?: string;
    prevRating?: number;
    setIsPostReviewPopupOpen: (value: boolean) => void;
}

const PostOrEditReviewPopup = ({
                                   isEdit,
                                   handleAddReview,
                                   handleEditReview,
                                   prevText,
                                   prevRating,
                                   setIsPostReviewPopupOpen
                               }: IProps) => {
    const [rating, setRating] = useState<number>(isEdit && prevRating ? prevRating : 5);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const formik = useFormik({
        initialValues: {
            text: isEdit && prevText ? prevText : ""
        }, validationSchema: Yup.object({
            text: Yup.string().required("Required field")
        }),
        onSubmit: async (values) => {
            setIsLoading(true);
            if (isEdit) {
                handleEditReview && await handleEditReview(rating, values.text);
            } else {
                handleAddReview && await handleAddReview(rating, values.text);
            }
            setIsLoading(false);
            setIsPostReviewPopupOpen(false);
        }
    });

    const handleContainerClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
    };

    const handleStarClick = (value: number) => {
        setRating(value);
    };

    return (
        <div className={style.container} onClick={handleContainerClick}>
            <h2>{isEdit ? "Edit" : "Post"} a Review</h2>
            <div className={style.stars}>
                {[...Array(5)].map((item, index) =>
                    <AppButton key={index} type="text" disabled={false} additionalClassNames={style.starButton}
                               onClick={() => handleStarClick(index + 1)}
                               children={<StarIcon key={index} className={style.starIcon}
                                                   color={index + 1 > rating ? "#EEEEEE" : "default"} />} />
                )}
            </div>
            <textarea name="text"
                      placeholder="Review"
                      className={classnames(formik.touched.text && formik.errors.text && style.notAllowedFieldValue)}
                      value={formik.values.text}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
            />
            <AppButton type="contained"
                       disabled={isLoading}
                       additionalClassNames={style.button}
                       children={<>{isLoading ? <Loader className={style.loader} /> :
                           <p>{isEdit ? "Edit" : "Post"} Review</p>}</>}
                       onClick={formik.handleSubmit}
            />
        </div>
    );
};

export default PostOrEditReviewPopup;